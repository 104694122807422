export function toLocaleCurrency(value) {
  let valueFormat = value;
  if (Intl) {
    const formatter = Intl.NumberFormat('it-IT', {
      maximumFractionDigits: 0,
    });
    valueFormat = `$${formatter.format(value)}`;
  }
  return valueFormat;
}
