import Contraentrega from '../../../images/Contraentrega.svg';
import wompi from '../../../images/wompi.png';

const paymentMethods = [
  {
    name: 'ON_DELIVERY',
    value: 'Pago contra entrega',
    imgSrc: Contraentrega,
    sublabel: 'Pagas al recibir el pedido.',
  },
  {
    name: 'WOMPI',
    value: 'Wompi',
    imgSrc: wompi,
    sublabel: 'Pagas anticipado por Nequi, PSE, entre otros.',
  },
];

export function getAvailablePaymentMethods(isOnDeliveryPaymentAvailable) {
  let response = [...paymentMethods];
  if (!isOnDeliveryPaymentAvailable) {
    response = paymentMethods.filter((paymentMethod) => paymentMethod.name !== 'onDeliveryPayment');
  }
  return response;
}

export function getPaymentMethodByCarrierAndCarrierParams(paymentMethod) {
  let response = null;
  if (paymentMethod.carrier === 'CREDIBANCO' && paymentMethod.carrierParams.specific === 'PSE') {
    response = { name: 'PSE', value: 'PSE', imgSrc: wompi };
  } else if (paymentMethod.carrier === 'CREDIBANCO') {
    response = { name: 'CREDIT_CARD', value: 'Tarjeta de crédito', imgSrc: wompi };
  } else {
    response = paymentMethods.find((value) => value.name === paymentMethod.carrier);
  }
  return response;
}

export default paymentMethods;
