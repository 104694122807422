import { firstLetterToUpperCase } from './strings_helper';
import { toLocaleCurrency } from './currency_helper';

export function getProductDimensionsByProductsConfigurations(productsConfigurations) {
  const variations = new Map();
  productsConfigurations.forEach((productConfiguration) => {
    if (!productConfiguration.isAvailable) {
      return;
    }
    const { productConfigurationValues } = productConfiguration;
    productConfigurationValues.forEach((productConfigurationValue) => {
      const { dimensionValue } = productConfigurationValue;
      const { dimension } = dimensionValue;
      if (variations.has(dimension.name)) {
        // Search if dimension exists by its name as key
        const possibleValuesMap = variations.get(dimension.name);
        if (!possibleValuesMap.has(dimensionValue.id)) {
          // Add new value to dimension when doesn't find it in the map
          possibleValuesMap.set(dimensionValue.id, dimensionValue.value);
        }
      } else {
        // Create new entry in the map
        const possibleValuesMap = new Map();
        possibleValuesMap.set(dimensionValue.id, dimensionValue.value);
        variations.set(dimension.name, possibleValuesMap);
      }
    });
  });
  return variations;
}

export function getDefaultValueForDimension(productDimensions, dimensionKey) {
  const dimensionValuesMap = productDimensions.get(dimensionKey);
  const dimensionValuesMapKeys = Array.from(dimensionValuesMap.keys());
  return {
    key: dimensionValuesMapKeys[0],
    value: dimensionValuesMap.get(dimensionValuesMapKeys[0]),
  };
}

export function getProductConfigurationBySelectedVariations(
  productConfigurations,
  selectedProductVariations,
) {
  let response = null;
  const selectedProductVariationsVerificationArray = [];
  // Transform Map to array
  const selectedProductVariationsKeys = Array.from(selectedProductVariations.keys());
  selectedProductVariationsKeys.forEach((productVariationKey) =>
    selectedProductVariationsVerificationArray.push({
      key: productVariationKey,
      value: selectedProductVariations.get(productVariationKey).value,
      found: false,
    }),
  );

  // Function to check if all keys did match with productConfiguration
  const isProductConfigurationFound = () => {
    let isFound = true;
    selectedProductVariationsVerificationArray.forEach((verification) => {
      isFound = isFound && verification.found;
    });
    return isFound;
  };

  const resetVerificationsArray = () => {
    for (let i = 0; i < selectedProductVariationsVerificationArray.length; i += 1) {
      selectedProductVariationsVerificationArray[i].found = false;
    }
  };

  // Iterate over product productConfigurations
  for (let i = 0; i < productConfigurations.length && !response; i += 1) {
    const { productConfigurationValues } = productConfigurations[i];
    // Iterate over productVerificationsArray to compare keys
    for (let j = 0; j < selectedProductVariationsVerificationArray.length && !response; j += 1) {
      const currentProductVerification = selectedProductVariationsVerificationArray[j];
      // Iterate over each productConfiguration variation values to compare keys
      for (
        let k = 0;
        k < productConfigurationValues.length && !currentProductVerification.found;
        k += 1
      ) {
        const currentProductConfigurationValue = productConfigurationValues[k];
        const { dimensionValue } = currentProductConfigurationValue;
        if (
          currentProductVerification.key === dimensionValue.dimension.name &&
          currentProductVerification.value === dimensionValue.value
        ) {
          selectedProductVariationsVerificationArray[j].found = true;
          currentProductVerification.found = true;
        }
      }
    }
    if (isProductConfigurationFound() && productConfigurations[i].isAvailable) {
      response = productConfigurations[i];
    } else {
      resetVerificationsArray();
    }
  }
  return response;
}

export function productPropertiesArrayToString(productPropertiesArray) {
  let response = '';
  productPropertiesArray.forEach((property, index) => {
    response += `${firstLetterToUpperCase(property.name)}: ${firstLetterToUpperCase(
      property.value,
    )}`;
    if (index < productPropertiesArray.length) {
      response += ', ';
    }
  });
  return response;
}

export function getProductPriceWithShippingCost(retailPrice, isShippingCostIncluded, shippingCost) {
  if (!isShippingCostIncluded) return toLocaleCurrency(retailPrice);
  let currentShippingCost;
  if (shippingCost !== undefined) {
    currentShippingCost = shippingCost;
  } else currentShippingCost = 0;
  return toLocaleCurrency(retailPrice + currentShippingCost);
}

export function productConfigurationHasDiscount(productConfiguration) {
  if (
    productConfiguration?.discount &&
    new Date(productConfiguration.discount.validFrom) <= new Date() &&
    new Date(productConfiguration.discount.validUntil) >= new Date()
  )
    return true;
  return false;
}
