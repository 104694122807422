import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Button, notification, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { toCurrency, getCoverImage } from '../../utils/formatters';
import { Context } from '../../GlobalState/GlobalState';
import { fetchInventory } from '../../gateway/warehouse-service-api';

function getButtonStyle(isDisabled) {
  const style = { height: '3em', margin: '2% 10%', borderRadius: '5px' };

  if (isDisabled) return { ...style };
  return { ...style, background: '#4A5782', borderColor: '#4A5782' };
}

function getInventoryStyle(inventory) {
  const style = { marginLeft: '5px' };
  const availableStock = inventory || 0;
  if (availableStock < 5) return { ...style, color: 'red' };
  return style;
}

function isAddProductToCartDisabled(selectedProductConfig, inventoryHash, desiredQuantity) {
  const availableStock = inventoryHash[selectedProductConfig.id] || 0;
  return (
    !selectedProductConfig ||
    !selectedProductConfig.id ||
    availableStock < desiredQuantity ||
    desiredQuantity === 0
  );
}

function isContinueToCheckoutButtonDisabled(cartItems, inventoryHash) {
  if (cartItems.length === 0) return true;

  for (let i = 0; i < cartItems.length; i += 1) {
    const { productConfiguration, quantity } = cartItems[i];
    const availableStock = inventoryHash[productConfiguration.id] || 0;
    if (availableStock < quantity) {
      return true;
    }
  }

  return false;
}

export const AddProductToCart = ({ product, productConfiguration, quantity }) => {
  const [state, dispatch] = useContext(Context);
  if (!product || !productConfiguration) {
    return <div />;
  }

  const isDisabled = isAddProductToCartDisabled(
    productConfiguration,
    state.inventoryHash,
    quantity,
  );
  const { name, reference, productMedia, catalog } = product;
  const { retailPrice, id, productConfigurationValues } = productConfiguration;
  return (
    <Button
      type="primary"
      onClick={() => {
        dispatch({
          type: 'ADD_PRODUCT',
          cartItem: {
            product: { name, reference, productMedia },
            productConfiguration: { retailPrice, id, productConfigurationValues },
            quantity,
            companyName: catalog.company.name,
          },
        });
        notification.success({
          message: 'Añadido al carrito!',
          duration: 1,
          style: { marginTop: '3em', width: 300 },
        });
      }}
      disabled={isDisabled}
      style={getButtonStyle(isDisabled)}
    >
      <div style={isDisabled ? { color: 'grey' } : { color: 'white' }}>Agregar al carrito</div>
    </Button>
  );
};

export const DeleteProductFromCart = ({ productConfigurationId }) => {
  const [, dispatch] = useContext(Context);

  return (
    <Button
      onClick={() => {
        dispatch({
          type: 'REMOVE_PRODUCT',
          productConfigurationId,
        });
        notification.success({
          message: 'Eliminado del carrito!',
          duration: 1,
          style: { marginTop: '3em' },
        });
      }}
    >
      <DeleteOutlined />
    </Button>
  );
};

const Cart = () => {
  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);

  // Update inventory in the cart to keep it sync
  useEffect(async () => {
    let counter = 0;
    while (counter < 4) {
      if (state.cartItems.length === 0) {
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      const inventory = await fetchInventory(
        state.cartItems.map((cartItem) => cartItem.productConfiguration.id),
        state.destinationCity.cityCode,
      );

      const groupedInventory = {};

      for (let i = 0; i < inventory.length; i += 1) {
        const { productConfigurationId, stock } = inventory[i];

        if (!groupedInventory[productConfigurationId]) {
          groupedInventory[productConfigurationId] = 0;
        }
        groupedInventory[productConfigurationId] += stock;
      }

      for (let i = 0; i < inventory.length; i += 1) {
        const { productConfigurationId } = inventory[i];
        // eslint-disable-next-line no-param-reassign
        state.inventoryHash[productConfigurationId] = groupedInventory[productConfigurationId];
      }

      dispatch({ type: 'SET_INVENTORY', inventoryHash: state.inventoryHash });
      counter += 1;
      // eslint-disable-next-line no-await-in-loop
      await new Promise((r) => setTimeout(r, 30000));
    }
  }, [state.destinationCity.cityCode, state.cartItems.length]);

  const addShippingPrice = state.store.isShippingPriceIncluded ? 10900 : 0;
  const isDisabled = isContinueToCheckoutButtonDisabled(state.cartItems, state.inventoryHash);

  return (
    <div style={{ margin: '6.5em 10%' }}>
      <div style={{ fontSize: '1.3em', marginBottom: '1em' }}>Los productos en tu carrito:</div>
      <br />
      {state.cartItems.map((cartItem) => (
        <div key={cartItem.productConfiguration.id} style={{ marginTop: '2em' }}>
          <Row gutter={24}>
            <Col span={8} md={4} sm={8}>
              <img
                src={getCoverImage(cartItem.product.productMedia, '_500x500')}
                alt="Img"
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: 'auto',
                  borderRadius: '12px ',
                  textAlign: 'center',
                }}
              />
            </Col>
            <Col span={14} md={18} sm={14}>
              <div>{`${cartItem.product.name}`}</div>
              <div style={{ display: 'inline-flex' }}>
                Inventario disponible:
                <div
                  style={getInventoryStyle(state.inventoryHash[cartItem.productConfiguration.id])}
                >
                  {`${state.inventoryHash[cartItem.productConfiguration.id] || 0}`}
                </div>
              </div>
              <br />
              <div style={{ display: 'inline-flex' }}>
                {toCurrency(cartItem.productConfiguration.retailPrice + addShippingPrice)}
                <div style={{ marginLeft: '1em' }}>{`X ${cartItem.quantity}`}</div>
              </div>
              <br />
              <div style={{ margin: '1em' }}>
                {cartItem.productConfiguration.productConfigurationValues.map((pcValue) => (
                  <div key={pcValue.dimensionValue.dimension.name}>
                    {pcValue.dimensionValue.dimension.name}: {pcValue.dimensionValue.value}
                  </div>
                ))}
              </div>
            </Col>
            <Col span={2} md={2} sm={2}>
              <DeleteProductFromCart productConfigurationId={cartItem.productConfiguration.id} />
            </Col>
          </Row>
        </div>
      ))}

      <Divider />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Button
          type="primary"
          onClick={() => navigate.push(`/checkout`)}
          disabled={isContinueToCheckoutButtonDisabled(state.cartItems, state.inventoryHash)}
          style={getButtonStyle(isDisabled)}
        >
          {state.cartItems.length === 0 && (
            <div style={{ color: 'grey' }}>Añade productos a tu carrito</div>
          )}
          {isDisabled && state.cartItems.length !== 0 && (
            <div style={{ color: 'grey' }}>Hay productos sin inventario.</div>
          )}
          {!isDisabled && <div style={{ color: 'white' }}>Continuar</div>}
        </Button>
        <br />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

AddProductToCart.propTypes = {
  productConfiguration: PropTypes.object,
  product: PropTypes.object,
  quantity: PropTypes.number,
};

DeleteProductFromCart.propTypes = {
  productConfigurationId: PropTypes.string,
};

export default Cart;
