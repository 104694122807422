import React, { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { fetchCities } from '../../gateway/company-service-api';
import search from '../../assets/icons/search.svg';
import radioButton from '../../assets/icons/radio-button.svg';
import radioButtonSelected from '../../assets/icons/radio-button-selected.svg';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import './ModalSelectCity.scss';
import ModalBase from '../ModalBase/ModalBase';

import { Context } from '../../GlobalState/GlobalState';

function ModalSelectCity({ handleCityChange, city = null, closeModal }) {
  const [cities, setCities] = useState(null);
  const [loading, setLoading] = useState(false);
  const [context] = useContext(Context);

  const getCities = async (event) => {
    setLoading(true);
    try {
      setCities(await fetchCities(event?.target.value || ''));
    } catch (error) {
      // Error message
    }
    setLoading(false);
  };

  const debouncedChangeHandler = useMemo(() => debounce(getCities, 500), []);
  let hasToShowCloseButtonOnModal;
  if (context.destinationCity) {
    hasToShowCloseButtonOnModal = true;
  } else hasToShowCloseButtonOnModal = false;

  return (
    <ModalBase
      isVisible
      title="Ciudad de destino"
      onClose={() => closeModal()}
      size="xs"
      showCloseButton={hasToShowCloseButtonOnModal}
    >
      <div className="col no-padding" style={{ minHeight: '100px', maxHeight: '400px' }}>
        <div style={{ marginBottom: '10px', fontSize: '12px' }}>
          Selecciona tu ciudad para brindarte la mejor experiencia
        </div>
        {context.cartItems.length > 0 && (
          <div className="modalCitySelector__cartItemsNotification">
            <div className="icon__container">
              <ion-icon
                name="alert-circle-outline"
                style={{ width: '25px', height: '25px' }}
              ></ion-icon>
            </div>
            <div>
              Al cambiar la ciudad de destino puede que algunos productos de tu carrito no estén
              disponibles
            </div>
          </div>
        )}
        <div className="inputSection">
          <input
            type="text"
            onChange={debouncedChangeHandler}
            placeholder="Buscar ciudad"
            style={{ width: '100%' }}
            className="searchInput"
          />
          <img src={search} alt="searchIcon" className="searchIcon" />
        </div>
        {cities && (
          <div className="citiesSection">
            {loading && <OverlayLoader />}
            {cities?.length === 0 ? (
              <span className="emptySearch">No se encontro niguna ciudad.</span>
            ) : (
              cities?.map((cityItem) => (
                <button
                  key={cityItem.id}
                  type="button"
                  className="cityRow"
                  onClick={() => {
                    handleCityChange(cityItem);
                    closeModal();
                    window.location.reload();
                  }}
                >
                  <span>{cityItem.name}</span>
                  <img
                    src={cityItem.id === city?.id ? radioButtonSelected : radioButton}
                    alt="radioButton"
                    className="radioButton"
                  />
                </button>
              ))
            )}
          </div>
        )}
      </div>
    </ModalBase>
  );
}

ModalSelectCity.propTypes = {
  handleCityChange: PropTypes.func,
  city: PropTypes.any,
  closeModal: PropTypes.func,
};

export default ModalSelectCity;
