import React, { useEffect, useState, useContext } from 'react';
import './productCard.scss';
import PropTypes from 'prop-types';

// Images & logos
import ImageUnavailable from '../../assets/image_unavailable.jpg';

// Helpers
import {
  getProductPriceWithShippingCost,
  productConfigurationHasDiscount,
} from '../../utilities/products_helper';
import { Context } from '../../GlobalState/GlobalState';

export default function ProductCard({ product, isIncludingShippingPrice, handleProductClick }) {
  const [firstProductConfiguration, setFirstProductConfiguration] = useState(null);
  const [context] = useContext(Context);
  useEffect(() => {
    if (product.product && product.product.productConfigurations[0]) {
      setFirstProductConfiguration(product.product.productConfigurations[0]);
    }
  }, []);

  const renderProductPrice = () => {
    if (productConfigurationHasDiscount(firstProductConfiguration)) {
      return (
        <span>
          <span className="productPrice__retailPrice line-through">
            {getProductPriceWithShippingCost(
              firstProductConfiguration.retailPrice,
              isIncludingShippingPrice,
              context.shippingCost,
            )}
          </span>
          <span className="productPrice__discounted" style={{ marginLeft: '5px' }}>
            {getProductPriceWithShippingCost(
              firstProductConfiguration.discount.discountedRetailPrice,
              isIncludingShippingPrice,
              context.shippingCost,
            )}
          </span>
        </span>
      );
    }
    return (
      <span>
        {getProductPriceWithShippingCost(
          firstProductConfiguration.retailPrice,
          isIncludingShippingPrice,
          context.shippingCost,
        )}
      </span>
    );
  };

  // const renderProductLabels = () => (
  //   <div className="productCard__labelsContainer">
  //     {product.product.labels.map((label) => (
  //       <div
  //         className="product__label"
  //         style={{ backgroundColor: label.backgroundColor, color: label.textColor }}
  //         key={label.name}
  //       >
  //         {label.name}
  //       </div>
  //     ))}
  //   </div>
  // );

  return (
    <div className="ProductCard" onClick={() => handleProductClick(product)} aria-hidden="true">
      <div className="productCard__imageContainer">
        {product.product && product.product.productMedia && product.product.productMedia[0] ? (
          <img
            src={product.product.productMedia[0].mediaURL}
            className="productCard__image"
            alt=""
          />
        ) : (
          <img src={ImageUnavailable} alt="" className="productCard__image"></img>
        )}
      </div>
      <div className="productCard__infoContainer">
        <div className="infoContainer__info">
          <div className="info__leftColumn">
            <div className="info__productName">{product.name}</div>
            {firstProductConfiguration && (
              <div className="info__productPrice">{renderProductPrice()}</div>
            )}
          </div>
        </div>
        {
          // product.product.labels.length > 0 && (
          //   <div className="infoContainer__labels">{renderProductLabels()}</div>
          // )
        }
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.any,
  isIncludingShippingPrice: PropTypes.bool,
  handleProductClick: PropTypes.func,
};
