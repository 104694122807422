import React, { useContext, useEffect } from 'react';
import './HorizontalCategoryPicker.scss';

import BaseCarousel from '../BaseCarousel/BaseCarousel';

import { firstLetterToUpperCase } from '../../utilities/strings_helper';
import { Context } from '../../GlobalState/GlobalState';
import { fetchStoreCategories } from '../../gateway/store-service-api';

export default function CategoriesHorizontalPicker() {
  const [context, dispatch] = useContext(Context);
  const { categoryId } = context;
  const storeId = context.store.id;
  const { storeCategories } = context;

  useEffect(() => {
    fetchStoreCategories(storeId).then((res) => {
      dispatch({ type: 'ADD_STORE_CATEGORIES', storeCategories: res });
    });
  }, []);

  const onSetCurrentCategory = async (category, e) => {
    e.stopPropagation();
    dispatch({ type: 'CHANGE_CATEGORY_ID', categoryId: category?.id || null });
  };

  const isCurrentCategory = (category) => categoryId && category.id === categoryId;

  return (
    <div className="CategoriesHorizontalPicker">
      <BaseCarousel carouselId="categoriesHorizontalPicker">
        <div className="categories__container">
          {storeCategories &&
            storeCategories.map((category) => (
              <div
                className={`category__container ${isCurrentCategory(category) && 'current'}`}
                key={category.id}
                onClick={(e) => onSetCurrentCategory(category, e)}
                aria-hidden="true"
              >
                {category.imageURL && (
                  <img src={category.imageURL} className="category__image" alt="" />
                )}
                <span className="category__name">{firstLetterToUpperCase(category.name)}</span>
                {isCurrentCategory(category) && (
                  <span
                    className="deselectCategory__button"
                    onClick={(e) => onSetCurrentCategory(null, e)}
                    aria-hidden="true"
                  >
                    <ion-icon name="close-outline" style={deselectButtonStyle}></ion-icon>
                  </span>
                )}
              </div>
            ))}
        </div>
      </BaseCarousel>
    </div>
  );
}

const deselectButtonStyle = {
  minHeight: '20px',
  maxHeight: '20px',
  minWidth: '20px',
  maxWidth: '20px',
};

CategoriesHorizontalPicker.propTypes = {};
