import React from 'react';
import './checkoutPaymentMethod.scss';
import PropTypes from 'prop-types';

// Icons
import RadioButton from '../../../assets/icons/radio-button.svg';
import RadioButtonSelected from '../../../assets/icons/radio-button-selected.svg';

export default function CheckoutPaymentMethod({
  paymentMethod,
  isCurrentMethod,
  handleMethodClick,
}) {
  return (
    <div
      className="CheckoutPaymentMethod"
      onClick={() => handleMethodClick(paymentMethod)}
      aria-hidden
    >
      <img
        src={isCurrentMethod ? RadioButtonSelected : RadioButton}
        style={{ marginRight: '15px' }}
        alt=""
      />
      <img src={paymentMethod.imgSrc} className="paymentMethod__icon" alt="" />
      <div className="cont-payment-option-text">
        <span className="payment-options-text">{paymentMethod.value}</span>
        <span className="payment-options-sub-text">{paymentMethod.sublabel}</span>
      </div>
    </div>
  );
}

CheckoutPaymentMethod.propTypes = {
  paymentMethod: PropTypes.any,
  isCurrentMethod: PropTypes.bool,
  handleMethodClick: PropTypes.func,
};
