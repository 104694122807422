import React from 'react';
import PropTypes from 'prop-types';
import './Filter.scss';

function Filter({ value, handleClick }) {
  return (
    <button className="FilterContainer" type="button" onClick={() => handleClick()}>
      <span className="filter__value">{value}</span>
      <span className="openFilter__iconContainer">
        <ion-icon name="chevron-down-outline"></ion-icon>
      </span>
    </button>
  );
}

Filter.propTypes = {
  value: PropTypes.string,
  handleClick: PropTypes.func,
};

export default Filter;
