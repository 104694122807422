export function getOptions(product) {
  const options = {};
  const verifier = {};
  const { productConfigurations } = product.product;
  for (let j = 0; productConfigurations && j < productConfigurations.length; j += 1) {
    const variant = productConfigurations[j];
    for (let k = 0; k < variant.productConfigurationValues.length; k += 1) {
      const property = variant.productConfigurationValues[k];
      const { value } = property.dimensionValue;
      const { name } = property.dimensionValue.dimension;
      if (!options[name]) {
        options[name] = [];
        verifier[name] = {};
      }
      if (!verifier[name][value] && variant.isAvailable) {
        verifier[name][value] = true;
        options[name].push({ label: value, value });
      }
    }
  }
  return options;
}
