import displayErrorMessage from './displayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export async function validateRoute(cityCodeDestination) {
  try {
    const url = `${ls.get('gatewayServiceApi')}/shipping-service-api/public/validate-routes`;
    const { data } = await axios({ method: 'post', url, data: { cityCodeDestination } });
    return data;
  } catch (error) {
    displayErrorMessage(
      'Error validando ruta',
      error && error.response && error.response.data && error.response.data.message,
    );
    return null;
  }
}

export async function fetchShippingCost(shippingLocationInfo) {
  let url = `${ls.get(
    'gatewayServiceApi',
  )}/shipping-service-api/public/valienta-shipment-quotation`;
  url += `?destinationStateId=${shippingLocationInfo.state.id}`;
  url += `&destinationCityId=${shippingLocationInfo.id}`;

  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  try {
    const result = await axios({ method: 'get', url, headers });
    return result.data;
  } catch (e) {
    displayErrorMessage('Error recuperando costo de envío', e);
    return 0;
  }
}
