import React, { useState, useEffect, useContext } from 'react';
import './checkout.scss';

// Ant Components
import { notification } from 'antd';

// Navigation
import { useNavigate } from 'react-router-dom';

// Sub-views
import ShippingInfoStep from './ShippingInfoStep/ShippingInfoStep';
import PaymentInfoStep from './PaymentInfoStep/PaymentInfoStep';
import OrderSummaryStep from './OrderSummaryStep/OrderSummaryStep';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import BackButton from '../../components/BackButton/BackButton';
import CheckoutSummary from '../../components/Checkout/CheckoutSummary/CheckoutSummary';
import CheckoutSteps from '../../components/Checkout/CheckoutSteps/CheckoutSteps';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

// API
import { calculateOrderCosts, createOrder } from '../../gateway/store-service-api';
import { customEvents, events, trackEvent } from '../../utils/trackEvent';
import { getProductsTotals } from '../../utilities/cart_helper';

const ls = require('local-storage');
export default function Checkout() {
  const [context, dispatch] = useContext(Context);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(null);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [orderDryRun, setOrderDryRun] = useState(null);
  const [shippingFormErrors, setShippingFormErrors] = useState([]);
  const [shippingFormUserErrors, setShippingFormUserErrors] = useState([]);
  const [shippingCost, setShippingCost] = useState(0);
  const [shippingCarrierIdByWarehouse, setShippingCarrierIdByWarehouse] = useState({});
  const [shippingForm, setShippingForm] = useState({
    cityCode: '',
    firstName: '',
    lastName: '',
    phone: '',
    phoneCode: '+57',
    street1: '',
    street2: '',
    indications: '',
  });

  const [paymentForm, setPaymentForm] = useState({
    carrier: '',
    carrierParams: {},
  });

  useEffect(() => {
    setCurrentStep(ls.get('checkoutCurrentStep') ? ls.get('checkoutCurrentStep') : 1);
    const lsShippingForm = ls.get('shippingForm');
    if (lsShippingForm) {
      setShippingForm({ ...lsShippingForm, cityCode: context.destinationCity.governmentCode });
    } else {
      setShippingForm({ ...shippingForm, cityCode: context.destinationCity.governmentCode });
    }
    const lsPaymentForm = ls.get('paymentForm');
    if (lsPaymentForm) {
      setPaymentForm({ ...lsPaymentForm });
    }
    trackEvent(events.initiateCheckout, context.cartItems);
    return () => setCurrentStep(1);
  }, []);

  useEffect(() => {
    if (currentStep > 1) {
      if (!validateShippingForm()) {
        setCurrentStep(1);
      } else if (currentStep > 2) {
        if (!paymentForm.carrier.length) {
          setCurrentStep(2);
        } else {
          ls.set('checkoutCurrentStep', currentStep);
          if (!orderDryRun) handlePaymentInfoChange({ name: ls.get('paymentForm').carrier });
        }
      } else {
        ls.set('checkoutCurrentStep', currentStep);
      }
    } else {
      ls.set('checkoutCurrentStep', currentStep);
      if (currentStep)
        onCalcultateValues(
          context.store.id,
          context.cartItems,
          false,
          'CREDIBANCO',
          {},
          {
            firstName: context.clientName || 'Jane',
            lastName: context.clientSurnames || 'Smith',
            street1: '123 Fake Street',
            street2: context.street_2 || '2131313123',
            indications: context.street_indications || 'indications',
            phone: '7777777777',
            phoneCode: '+57',
            cityCode: context.destinationCity.governmentCode,
            stateCode: context.destinationCity?.state?.governmentCode || '11',
            countryAlpha3Code: 'COL',
          },
        );
    }
  }, [currentStep]);

  const onCalcultateValues = async (
    id,
    cartItems,
    calculateShippingCost,
    carrierName,
    carrierParams,
    info,
  ) => {
    setIsCreatingOrder(true);
    const response = await calculateOrderCosts(
      id,
      cartItems,
      calculateShippingCost,
      carrierName,
      carrierParams,
      info,
    );
    setOrderDryRun(response.data);
    setIsCreatingOrder(false);
  };

  const validateShippingForm = () => {
    const errors = [];
    const userInfoErrors = [];
    if (!shippingForm.firstName.length) {
      errors.push('firstName');
      userInfoErrors.push('Nombre');
    }
    if (!shippingForm.lastName.length) {
      errors.push('lastName');
      userInfoErrors.push('Apellido');
    }
    if (!shippingForm.phone.length) {
      errors.push('phone');
      userInfoErrors.push('Celular');
    }
    if (!shippingForm.street1.length) {
      errors.push('address');
      userInfoErrors.push('Dirección');
    }
    if (shippingForm.street1.length > 100) {
      errors.push('longAddress');
      userInfoErrors.push('Dirección');
    }
    if (!shippingForm.street2.length) {
      errors.push('addressDetails');
      userInfoErrors.push('Detalle de dirección');
    }
    if (shippingForm.street2.length > 50) {
      errors.push('longAddressDetail');
      userInfoErrors.push('Detalle de dirección');
    }
    if (shippingForm.indications.length > 50) {
      errors.push('longIndications');
      userInfoErrors.push('Indicaciones');
    }
    setShippingFormErrors(errors);
    setShippingFormUserErrors(userInfoErrors);
    return errors.length === 0;
  };

  const handleMainButtonClick = () => {
    if (currentStep === 1) {
      const isShippingFormValid = validateShippingForm();
      if (isShippingFormValid) {
        ls.set('shippingForm', shippingForm);
        trackEvent(customEvents.inputClientInfo, { ...shippingForm });
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      ls.set('paymentForm', paymentForm);
      trackEvent(customEvents.selectPaymentMethod, {
        ...paymentForm,
        shippingCost,
        total: getProductsTotals(context.cartItems, 0).baseTotal,
      });
      setCurrentStep(3);
    } else if (currentStep === 3) {
      generateOrder();
    }
  };

  const generateOrder = async () => {
    if (!orderDryRun.totalProductCosts) {
      notification.error({
        message: 'Lo sentimos',
        description: `No pudimos generar tu orden, por favor comunícate con soporte para que te ayudemos a solucionar el problema`,
        duration: null,
      });
    } else {
      setIsCreatingOrder(true);
      /* const { id } = await createOrder(
        context.store.id,
        context.cartItems,
        false,
        paymentForm.carrier,
        paymentForm.carrierParams && paymentForm.carrierParams.specific
          ? {}
          : paymentForm.carrierParams,
        {
          ...shippingForm,
          stateCode: context.destinationCity.state.governmentCode,
          countryAlpha3Code: 'COL',
        },
      ); */
      const { id } = await createOrder(
        context.store.name,
        context.store.id,
        context.cartItems,
        paymentForm.carrier,
        paymentForm.carrierParams && paymentForm.carrierParams.specific
          ? {}
          : paymentForm.carrierParams,
        {
          ...shippingForm,
          stateCode: context.destinationCity.state.governmentCode,
          countryAlpha3Code: 'COL',
        },
        shippingCarrierIdByWarehouse,
      );
      const prices = getProductsTotals(context.cartItems, shippingCost);
      trackEvent(events.purchase, {
        total: prices.retailTotal,
        totalCost: prices.sellerTotal,
        cartProducts: context.cartItems,
      });
      setIsCreatingOrder(false);
      dispatch({ type: 'EMPTY_CART' });
      dispatch({ type: 'SET_LAST_ORDER_ID', lastOrderId: id });
      navigate(`/mi-orden?orderId=${id}&status=success`);
    }
  };

  const getShippingCost = (shippingList) => {
    const keys = Object.keys(shippingList).filter((item) => typeof shippingList[item] === 'object');
    const resp = keys.reduce(
      (accum, item) => {
        const carrier = shippingList[item].shippingCost;
        const aux = accum;
        aux.shippingCarrierIdByWarehouse[item] = carrier.carrierId;
        return {
          ...accum,
          price: accum.price + carrier.plazaPrice,
        };
      },
      { shippingCarrierIdByWarehouse: {}, price: 0 },
    );
    return resp;
  };

  const handlePaymentInfoChange = async (paymentMethod) => {
    try {
      if (paymentMethod.name !== '') {
        setIsCreatingOrder(true);
        const carrier = paymentMethod.name;
        const carrierParams = {
          name: paymentMethod.name === 'WOMPI' ? 'Flipcat Plaza' : '',
          description: paymentMethod.name === 'WOMPI' ? `Resumen de la compra` : '',
        };
        setPaymentForm({
          carrier,
          carrierParams,
        });
        const response = await calculateOrderCosts(
          context.store.id,
          context.cartItems,
          true,
          carrier,
          carrierParams,
          {
            ...shippingForm,
            stateCode: context.destinationCity.state.governmentCode,
            countryAlpha3Code: 'COL',
          },
        );
        const shippmentsAndCost = getShippingCost(response.data);
        setShippingCost(shippmentsAndCost.price);
        setShippingCarrierIdByWarehouse(shippmentsAndCost.shippingCarrierIdByWarehouse);
        setOrderDryRun(response.data);
      }
    } catch (e) {
      let description =
        'Tuvimos problemas encontrando una opción para tu envío. Por favor comunícate con soporte para que te ayudemos a solucionar el problema';
      if (e.response) {
        if (e.response.data.message.includes('maxLength'))
          description =
            'Alguna de las direcciones sobrepasa la cantidad de caracteres permitidos. Verifica tu dirección de envío y vuelve a intentarlo';
      }
      notification.error({
        message: 'Lo sentimos',
        description,
        duration: null,
      });
    } finally {
      setIsCreatingOrder(false);
    }
  };

  return (
    <div className="Checkout">
      {isCreatingOrder && <OverlayLoader></OverlayLoader>}
      <BackButton></BackButton>
      <div style={{ marginTop: '20px' }}>
        <CheckoutSteps currentStep={currentStep} handleSetStep={setCurrentStep}></CheckoutSteps>
      </div>

      <div className="checkout__layout" style={{ marginTop: '20px' }}>
        <div className="checkout__stepColumn">
          {currentStep === 1 && (
            <ShippingInfoStep
              shippingForm={shippingForm}
              handleShippingInfoChange={setShippingForm}
              destinationCity={context.destinationCity}
              shippingFormErrors={shippingFormErrors}
              shippingFormUserErrors={shippingFormUserErrors}
            ></ShippingInfoStep>
          )}
          {currentStep === 2 && (
            <PaymentInfoStep
              paymentForm={paymentForm}
              handlePaymentInfoChange={handlePaymentInfoChange}
            ></PaymentInfoStep>
          )}
          {currentStep === 3 && (
            <OrderSummaryStep
              shippingForm={shippingForm}
              paymentForm={paymentForm}
              handleEditStep={setCurrentStep}
            ></OrderSummaryStep>
          )}
        </div>
        <div className="checkout__summaryColumn">
          <div className={context.isMobileDevice ? 'checkoutSummary__floatingContainer' : ''}>
            <CheckoutSummary
              orderDryRun={orderDryRun}
              currentStep={currentStep}
              shippingCost={shippingCost}
              onMainButtonClick={handleMainButtonClick}
            ></CheckoutSummary>
          </div>
        </div>
      </div>
    </div>
  );
}
