import React from 'react';
import './checkoutSteps.scss';
import PropTypes from 'prop-types';

export default function CheckoutSteps({ currentStep, handleSetStep }) {
  return (
    <div className="CheckoutSteps">
      <span className="checkoutStep__container">
        <div
          className={`checkoutStep ${currentStep === 1 && 'active'}`}
          onClick={() => handleSetStep(1)}
          aria-hidden
        >
          Tus datos
        </div>
      </span>
      {currentStep >= 2 && (
        <span className="checkoutStep__container">
          <div className={`step__separator ${currentStep === 1 && 'active'}`}>{'>'}</div>
          <div
            className={`checkoutStep ${currentStep === 2 && 'active'}`}
            onClick={() => handleSetStep(2)}
            aria-hidden
          >
            Métodos de pago
          </div>
        </span>
      )}
      {currentStep >= 3 && (
        <span className="checkoutStep__container">
          <div className={`step__separator ${currentStep === 2 && 'active'}`}>{'>'}</div>
          <div
            className={`checkoutStep ${currentStep === 3 && 'active'}`}
            onClick={() => handleSetStep(3)}
            aria-hidden
          >
            Resumen
          </div>
        </span>
      )}
    </div>
  );
}

CheckoutSteps.propTypes = {
  currentStep: PropTypes.number,
  handleSetStep: PropTypes.func,
};
