import { notification } from 'antd';
import displayErrorMessage from './displayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchStore = async () => {
  const host =
    process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'play'
      ? process.env.REACT_APP_STORE.split('.')
      : window.location.host.split('.');
  const [storeUrl] = host;
  const storeServiceApi = process.env.REACT_APP_STORE_SERVICE_API;
  let url = `${ls.get('storeServiceApi') || storeServiceApi}`;
  url += `/store?storeUrl=${storeUrl}`;
  try {
    const result = await axios({ method: 'get', url });
    return result.data;
  } catch (e) {
    return {};
  }
};

export const fetchProducts = async (
  storeId,
  page,
  categoryId,
  companyId,
  searchTerm,
  orderBy,
  destinyCityCode,
  inventoryHash,
) => {
  let url = `${ls.get('storeServiceApi')}/store/${storeId}/storeProduct`;
  url += `?page=${page}&pageSize=25`;
  if (categoryId) url += `&categoryId=${categoryId}`;
  if (companyId) url += `&companyId=${companyId}`;
  if (orderBy) url += `&orderBy=${orderBy}`;
  if (searchTerm && searchTerm.trim() !== '') url += `&searchTerm=${searchTerm.trim()}`;
  try {
    const result = await axios({ method: 'get', url });
    const fullListOfProducts = result.data;
    const totalPages = parseInt(result.headers['pagination-total'], 10);
    const products = removeUnavailableProducts(fullListOfProducts);
    return { products, totalPages, totalProducts: totalPages };
  } catch (e) {
    displayErrorMessage('Error recuperando los productos de la tienda', e);
  }
  return { products: [], totalPages: 0, inventoryHash };
};

function removeUnavailableProducts(fullListOfProducts) {
  const products = [];

  for (let i = 0; i < fullListOfProducts.length; i += 1) {
    const product = fullListOfProducts[i];

    if (
      !product.product.isDisabled &&
      product.product.catalog.allowOrders &&
      product.product.catalog.isPublic &&
      product.product.totalStock > 0
    ) {
      products.push(product);
    }
  }

  return products;
}

export const fetchStoreCategories = async (storeId) => {
  let url = `${ls.get('storeServiceApi')}/store/${storeId}/category`;
  url += '?page=0&pageSize=200';
  try {
    const result = await axios({ method: 'get', url });
    return result.data;
  } catch (e) {
    displayErrorMessage('Error recuperando las categorias de la tienda', e);
  }
  return [];
};

export const fetchProductById = async (storeId, productId) => {
  const url = `${ls.get('storeServiceApi')}/store/${storeId}/storeProduct/${productId}`;
  try {
    const result = await axios({ method: 'get', url });
    const product = result.data;
    return product;
  } catch (e) {
    console.log('Error getting product :>> ', e);
  }
  return undefined;
};

export const calculateOrderCosts = (
  ownerId,
  cartItems,
  dryRun,
  carrier,
  carrierParams,
  shippingAddress,
) => {
  if (cartItems.length === 0) {
    notification.warning({
      message: 'No has agregado items a tu carrito',
      duration: 5,
      style: { width: 300 },
    });
    return {};
  }
  const valientaProducts = cartItems.map((cartItem) => ({
    quantity: cartItem.quantity,
    productConfigurationId: cartItem.productConfiguration.id,
    priceVariation: 0,
  }));

  const data = {
    calculateShippingCost: dryRun,
    shippingCost: 0,
    ownerId,
    customerId: null,
    currencyCode: 'COP',
    orderTypeValue: 'RETAIL',
    payment: { carrier, carrierParams },
    shippingAddress,
    billingInfo: {},
    valientaProducts,
    channel: 'STORE',
  };

  const headers = { Authorization: `Bearer ${ls.get('authToken')}` };
  const url = `${ls.get('storeServiceApi')}/public/order-cost`;
  try {
    return axios({ method: 'post', url, headers, data });
  } catch (e) {
    displayErrorMessage('Error en creación de la Orden', e);
    return {};
  }
};

export const createOrder = async (
  storeName,
  ownerId,
  cartItems,
  carrier,
  carrierParams,
  shippingAddress,
  shippingCarrierIdByWarehouse,
) => {
  if (cartItems.length === 0) {
    notification.warning({
      message: 'No has agregado items a tu carrito',
      duration: 5,
      style: { width: 300 },
    });
    return {};
  }
  const url = `${ls.get('storeServiceApi')}/public/order`;

  const valientaProducts = cartItems.map((cartItem) => ({
    quantity: cartItem.quantity,
    productConfigurationId: cartItem.productConfiguration.id,
    priceVariation: 0,
  }));
  const data = {
    ownerId,
    customerId: null,
    currencyCode: 'COP',
    orderTypeValue: 'RETAIL',
    payment: { carrier, carrierParams },
    shippingAddress,
    billingInfo: { store: storeName },
    valientaProducts,
    channel: 'STORE',
    shippingCarrierIdByWarehouse,
  };

  try {
    const result = await axios({ method: 'post', url, data });
    return result.data;
  } catch (e) {
    displayErrorMessage('Error en creación de la Orden', e);
  }
  return {};
};
