import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import './configSentry';
// import './index.css';

ReactDOM.render(
  <HelmetProvider>
    <App style={{ fontFamily: 'Lato' }} />
  </HelmetProvider>,
  document.getElementById('root'),
);
