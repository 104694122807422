import React from 'react';
import './storeProductsSkeleton.scss';

// External components
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';

export default function StoreProductsSkeleton() {
  return (
    <div className="StoreProductsSkeleton">
      <div className="storeProductsSkeleton__grid">
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
        <SkeletonLoader width="100%" height="150px" borderRadius="14px"></SkeletonLoader>
      </div>
      <div className="storeProductsSkeleton__pagination">
        <SkeletonLoader height="40px" width="250px" borderRadius="8px"></SkeletonLoader>
      </div>
    </div>
  );
}
