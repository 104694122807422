import React from 'react';
import './backButton.scss';
import PropTypes from 'prop-types';

// Navigation
import { useNavigate } from 'react-router-dom';

export default function BackButton({ buttonText = 'Volver', handleGoBack }) {
  const navigate = useNavigate();
  const goBack = () => {
    if (handleGoBack) handleGoBack();
    else navigate(-1);
  };
  return (
    <div className="BackButton" onClick={() => goBack()} aria-hidden="true">
      <span className="backButton__iconContainer">
        <ion-icon name="chevron-back-outline" style={backButtonIconStyle}></ion-icon>
      </span>
      <span>{buttonText}</span>
    </div>
  );
}

const backButtonIconStyle = {
  minWidth: '18px',
  maxWidth: '18px',
  minHeight: '18px',
  maxHeight: '18px',
};

BackButton.propTypes = {
  buttonText: PropTypes.string,
  handleGoBack: PropTypes.func,
};
