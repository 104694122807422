import React, { useState } from 'react';
import './showHideText.scss';
import PropTypes from 'prop-types';

export default function ShowHideText({ text }) {
  const [isShowingText, setIsShowingText] = useState(false);
  const getText = () => {
    if (isShowingText) {
      return text;
    }
    return `${text.substring(0, 100)}...`;
  };

  const showHideText = () => {
    setIsShowingText(!isShowingText);
  };
  return (
    <div className="ShowHideText">
      <p>{getText()}</p>
      <span className="showHide__button" onClick={() => showHideText()} aria-hidden>
        {isShowingText ? 'Ver menos' : 'Ver más'}
      </span>
    </div>
  );
}
ShowHideText.propTypes = {
  text: PropTypes.string,
};
