import React, { useContext, useState, useEffect } from 'react';
import './cart.scss';

// Navigation
import { useNavigate } from 'react-router-dom';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import CartItemCard from '../../components/CartItemCard/CartItemCard';
import BackButton from '../../components/BackButton/BackButton';
import CartSummary from '../../components/CartSummary/CartSummary';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

// API
import { fetchInventory } from '../../gateway/warehouse-service-api';

export default function Cart() {
  const [context] = useContext(Context);
  const [inventories, setInventories] = useState(new Map());
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const fetchProductsIventories = async () => {
    setIsFetching(true);
    const productConfigurationIds = context.cartItems.map(
      (cartItem) => cartItem.productConfiguration.id,
    );
    const response = await fetchInventory(
      productConfigurationIds,
      context.destinationCity.governmentCode,
    );
    const inventoriesCopy = new Map(inventories);
    response.forEach((warehouseItem) => {
      inventoriesCopy.set(warehouseItem.productConfigurationId, warehouseItem);
    });
    setInventories(inventoriesCopy);
    setIsFetching(false);
  };

  useEffect(() => {
    if (context.destinationCity) {
      fetchProductsIventories();
    }
  }, [context.destinationCity]);

  if (context.cartItems.length === 0) {
    return (
      <div className="Cart empty">
        <div className="emptyCart__container">
          <div className="emptyCart__label">Aún no has agregado productos a tu carrito</div>
          <button type="button" className="button primary" onClick={() => navigate('/tienda')}>
            Volver a la tienda
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="Cart">
      <div className="backButton__container">
        <BackButton></BackButton>
      </div>

      <div className="cart__title">Carrito</div>
      <div className="cart__layout">
        <div className="cartProducts__container">
          {isFetching && <OverlayLoader></OverlayLoader>}
          {context.cartItems.map((cartItem) => (
            <div className="cartItem__container" key={cartItem.productConfiguration.id}>
              <CartItemCard
                cartItem={cartItem}
                maxQuantity={inventories.get(cartItem.productConfiguration.id)?.stock}
              ></CartItemCard>
            </div>
          ))}
        </div>
        <div className="cartSummary__container">
          <div className={!context.isMobileDevice ? 'summaryFloatingContainer' : ''}>
            <CartSummary></CartSummary>
          </div>
        </div>
      </div>
      {context.isMobileDevice && (
        <div className="cartButton__fixedContainer">
          <button
            type="button"
            className="button primary cartButton__fixedButton"
            onClick={() => navigate('/checkout')}
          >
            Realizar pedido
          </button>
          <div
            className="addMoreProducts__button"
            onClick={() => navigate('/tienda/productos')}
            aria-hidden
          >
            Agregar más productos
          </div>
        </div>
      )}
    </div>
  );
}
