import React, { useContext } from 'react';
import './cartItemCard.scss';
import PropTypes from 'prop-types';

// External components
import QuantityButtons from '../QuantityButtons/QuantityButtons';

// Context
import { Context } from '../../GlobalState/GlobalState';

// Utilities
import { firstLetterToUpperCase } from '../../utilities/strings_helper';
import {
  getProductPriceWithShippingCost,
  productConfigurationHasDiscount,
} from '../../utilities/products_helper';

export default function CartItemCard({ cartItem, maxQuantity }) {
  const [context, dispatch] = useContext(Context);
  const areAllCostsFetched = context.store && context.shippingCost;
  const getProductCoverImage = () => {
    const { productMedia } = cartItem.product;
    const coverImage = productMedia.find((image) => image.isCoverImage);
    return (
      <img
        src={coverImage?.mediaURL || productMedia[0].mediaURL}
        className="cartItemCard__coverImage"
        alt=""
      ></img>
    );
  };
  const getProductPrice = () => {
    const { productConfiguration } = cartItem;
    if (productConfigurationHasDiscount(productConfiguration)) {
      return (
        <span className="productInfo__discountedPriceContainer">
          <span className="originalPrice discount">
            {areAllCostsFetched &&
              getProductPriceWithShippingCost(
                productConfiguration.retailPrice,
                context.store.isShippingPriceIncluded,
                context.shippingCost,
              )}
          </span>
          <span className="discountedPrice">
            {areAllCostsFetched &&
              getProductPriceWithShippingCost(
                productConfiguration.discount.discountedRetailPrice,
                context.store.isShippingPriceIncluded,
                context.shippingCost,
              )}
          </span>
        </span>
      );
    }
    return (
      <span className="productInfo__discountedPriceContainer">
        <span className="originalPrice">
          {areAllCostsFetched &&
            getProductPriceWithShippingCost(
              productConfiguration.retailPrice,
              context.store.isShippingPriceIncluded,
              context.shippingCost,
            )}
        </span>
      </span>
    );
  };
  const getProductVariations = () => {
    const { productConfigurationValues } = cartItem.productConfiguration;
    return (
      <div className="productConfiguration__variationsContainer">
        {productConfigurationValues.map((value) => (
          <span className="variationContainer" key={value.id}>
            <span style={{ marginRight: '2px' }}>
              {firstLetterToUpperCase(value.dimensionValue.dimension.name)}:
            </span>
            <span style={{ fontWeight: '700' }}>
              {firstLetterToUpperCase(value.dimensionValue.value)}
            </span>
          </span>
        ))}
      </div>
    );
  };
  const handleProductQuantityChange = (quantity) => {
    if (cartItem.quantity + quantity > 0) {
      dispatch({
        type: 'ADD_PRODUCT',
        cartItem: { ...cartItem, quantity },
      });
    } else {
      dispatch({
        type: 'REMOVE_PRODUCT',
        productConfigurationId: cartItem.productConfiguration.id,
      });
    }
  };
  return (
    <div className="CartItemCard">
      <div className="cartItemCard__image">{getProductCoverImage()}</div>
      <div className="cartItemCard__infoContainer">
        <div className="productInfo__name">{cartItem.product.name}</div>
        <div className="productInfo__price">{getProductPrice()}</div>
        <div className="productInfo__variations">{getProductVariations()}</div>
        <div className="cartItemCard__quantityButtonsContainer">
          <div className="cartItemCard__inventoryValue">
            Unidades disponibles: <strong>{maxQuantity}</strong>
          </div>
          <QuantityButtons
            currentQuantity={cartItem.quantity}
            handleQuantityChange={handleProductQuantityChange}
            maxQuantity={maxQuantity}
          ></QuantityButtons>
        </div>
      </div>
    </div>
  );
}

CartItemCard.propTypes = {
  cartItem: PropTypes.any,
  maxQuantity: PropTypes.number,
};
