import { values } from 'lodash';
import get from 'lodash.get';

/**
 * Sends and event to Meta pixel
 * @param {string} eventName - The name of the event
 * @param {object} data - Object of data that will be sent with the event
 */
export function trackEvent(eventName, data = {}) {
  const refData = customizeData(eventName, data);
  trackEventWithMeta(eventName, refData);
}

function trackEventWithMeta(eventName, data) {
  // eslint-disable-next-line no-undef
  if (typeof fbq !== 'undefined') {
    const isStandard = Object.values(events).find((event) => event === eventName);
    if (isStandard) {
      // eslint-disable-next-line no-undef
      fbq('track', eventName, data);
    } else {
      // eslint-disable-next-line no-undef
      fbq('trackCustom', eventName, data);
    }
  }
}

export const events = {
  addToCart: 'AddToCart',
  customizeProduct: 'CustomizeProduct',
  initiateCheckout: 'InitiateCheckout',
  purchase: 'Purchase',
  viewContent: 'ViewContent',
};

export const customEvents = {
  inputClientInfo: 'InputClientInfo',
  selectPaymentMethod: 'SelectPaymentMethod',
};

const eventPayloadSchema = {
  AddToCart: {
    Nombre: {
      type: 'text',
      getter: 'product.name',
    },
    Variante: {
      type: 'array',
      getter: (n) => `productConfiguration.productConfigurationValues[${n}].dimensionValue.value`,
    },
    Cantidad: {
      type: 'text',
      getter: 'quantity',
    },
    Proveedor: { getter: 'companyName', type: 'text' },
    Costo: { getter: 'productConfiguration.sellerPrice', type: 'text' },
  },
  ViewContent: {
    Producto: {
      type: 'text',
      getter: 'product.name',
    },
    Url: {
      type: 'const',
      getter: () => window.location.href,
    },
  },
  CustomizeProduct: {
    Variante: {
      type: 'text',
      getter: 'value',
    },
  },
  InitiateCheckout: {
    Productos: {
      type: 'mappedArray',
      getter: (baseArray) =>
        baseArray.map((baseObj) => ({
          Nombre: baseObj.product.name,
          Cantidad: baseObj.quantity,
        })),
    },
  },
  SelectPaymentMethod: {
    Medio_de_pago: {
      type: 'text',
      getter: 'carrier',
    },
    Costo_de_envio: {
      type: 'text',
      getter: 'shippingCost',
    },
    total: {
      type: 'text',
      getter: 'total',
    },
  },
  Purchase: {
    Productos: {
      type: 'mappedArray',
      getter: (obj) =>
        get(obj, 'cartProducts').map((baseObj) => ({
          Nombre: baseObj.product.name,
          Cantidad: baseObj.quantity,
        })),
    },
    Total: {
      type: 'text',
      getter: 'total',
    },
    CostoTotal: {
      type: 'text',
      getter: 'totalCost',
    },
    Divisa: {
      type: 'const',
      getter: () => JSON.parse(localStorage.getItem('destinationCity')).state.country.currencyCode,
    },
  },
};

function customizeData(event, data) {
  const refData = {};
  const schema = eventPayloadSchema[event];
  if (schema) {
    const objKeys = Object.keys(schema);
    objKeys.forEach((key) => {
      const { type, getter } = schema[key];
      let configArray = null;
      switch (type) {
        case 'text':
          refData[key] = get(data, getter);
          break;
        case 'array':
          configArray = getDataArray(data, getter);
          refData[key] = configArray;
          break;
        case 'const':
          refData[key] = getter();
          break;
        case 'mappedArray':
          refData[key] = getter(data);
          break;
        default:
          break;
      }
    });
  }
  return refData;
}

function getDataArray(data, getter) {
  const configArray = [];
  let n = 0;
  while (n >= 0) {
    const fetchObj = get(data, getter(n), null);
    if (fetchObj) {
      configArray.push(fetchObj);
      n += 1;
      continue;
    } else {
      n = -1;
    }
    break;
  }
  return configArray;
}
