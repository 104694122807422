import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './sellerStore.scss';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import StoreProducts from '../../components/StoreProducts/StoreProducts';

// Icons
import FacebookLogo from '../../assets/icons/facebook.svg';
import InstagramLogo from '../../assets/icons/instagram.svg';
import WhatsAppLogo from '../../assets/icons/whatsapp-logo.svg';
import PadlockIconBlue from '../../assets/icons/padlock-blue.svg';
import HorizontalCategoryPicker from '../../components/HorizontalCategoryPicker/HorizontalCategoryPicker';
import { events, trackEvent } from '../../utils/trackEvent';

export default function SellerStore() {
  const [context] = useContext(Context);

  const onOpenFacebookLink = () => {
    window.open(context.store.facebook, '_blank');
  };

  const onOpenInstagramLink = () => {
    window.open(context.store.instagram, '_blank');
  };

  useEffect(() => {
    trackEvent(events.viewContent);
  }, []);

  const onOpenWhatsAppLink = () => {
    const cel = `${context.store.whatsapp}`.replace('+', '').replace(/ /g, '');
    window.open(`https://wa.me/${cel}`, '_blank');
  };

  return (
    <div className="SellerStore">
      {context.store && (
        <>
          <Helmet>
            <title>{context.store.url}</title>
            <meta name="description" content={context.store.description} />
            <meta property="og:type" content="artículos" />
            <meta property="og:title" content={context.store.url} />
            <meta property="og:description" content={context.store.description} />
            <meta property="og:image" content={context.store.banner} />
            <meta property="og:url" content={`https://${context.store.url}.flipcat.store`} />
            <meta property="og:site_name" content={context.store.url} />
          </Helmet>
          <div>
            <div className="storeBanner__container">
              <img src={context.store.banner} className="storeBanner" alt="Banner" />
            </div>
            <div className="storeInfo__container">
              {context.store.logo && (
                <div className="storeInfo__logoContainer">
                  <img src={context.store.logo} className="storeLogo" alt="Logo" />
                </div>
              )}
              <div
                className={
                  context.store.logo ? 'storeInfo__infoContainer' : 'storeInfo__infoContainer_alter'
                }
              >
                <div className="store__name">{context.store.name}</div>
                <div className="storeInfo__socialNetworksContainer">
                  {context.store.facebook && context.store.facebook.length > 0 && (
                    <div
                      className="socialNetworkButton"
                      onClick={() => onOpenFacebookLink()}
                      aria-hidden
                    >
                      <img
                        src={FacebookLogo}
                        className="socialNetworkButton__logo"
                        alt="Facebook"
                      />
                    </div>
                  )}
                  {context.store.instagram && context.store.instagram.length > 0 && (
                    <div
                      className="socialNetworkButton"
                      onClick={() => onOpenInstagramLink()}
                      aria-hidden
                    >
                      <img
                        src={InstagramLogo}
                        className="socialNetworkButton__logo"
                        alt="Istagram"
                      />
                    </div>
                  )}
                  {context.store.whatsapp && context.store.whatsapp.length > 0 && (
                    <div
                      className="socialNetworkButton"
                      onClick={() => onOpenWhatsAppLink()}
                      aria-hidden
                    >
                      <img
                        src={WhatsAppLogo}
                        className="socialNetworkButton__logo"
                        alt="Whatsapp"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="store__descriptionContainer">
              <div className="description__row">
                <span className="descriptionRow__label">
                  <img src={PadlockIconBlue} className="descriptionIcon" alt="" />
                  <span>Pago seguro:</span>
                </span>
                <span>Contra entrega o {context.workInNequi ? 'Nequi' : 'Wompi'}</span>
              </div>
            </div>
            {context.store.id && (
              <>
                <div className="store__horizontalCategoryPickerContainer">
                  <HorizontalCategoryPicker />
                </div>
                <div className="store__productsContainer">
                  <StoreProducts></StoreProducts>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
