import React, { useEffect, useState } from 'react';
import './orderCard.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

// Ant components
import { notification, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// Utilities
import { getPaymentMethodByCarrierAndCarrierParams } from '../../views/Checkout/PaymentInfoStep/paymentMethods';
import { toLocaleCurrency } from '../../utilities/currency_helper';

// Icons
import CopyIconBlue from '../../assets/icons/copy-blue.svg';
import ImageUnavailable from '../../assets/image_unavailable.jpg';
export default function OrderCard({ order, isFetchingOrder, isLastOrder, orderStatusPayment }) {
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    setPaymentMethod(
      getPaymentMethodByCarrierAndCarrierParams({
        carrier: order.paymentCarrier,
        carrierParams: order.paymentCarrierParams,
      }),
    );
  }, []);

  const paymentCarrierToLabel = (paymentCarrier) => {
    if (paymentCarrier === 'WOMPI') return 'Wompi';
    if (paymentCarrier === 'NEQUI') return 'Nequi';
    if (paymentCarrier === 'ON_DELIVERY') return 'Contraentrega';
    return 'Sin método de pago asignado';
  };

  const orderStatusToSpanish = (orderStatus) => {
    switch (orderStatus) {
      case 'ORDER_CREATED':
        return 'Procesando pedido';
      case 'ORDER_REJECTED':
        return 'Rechazada';
      case 'ORDER_IN_PACKING':
        return 'Alistando pedido';
      case 'ORDER_PACKED':
        return 'Empacada';
      case 'ORDER_IN_SHIPPING':
        return 'En camino';
      case 'ORDER_DELIVERED':
        return 'Entregada';
      case 'ORDER_CANCELED':
        return 'Cancelada';
      case 'PAYMENT_COMPLETED':
        return 'Pago completado';
      case 'PAYMENT_IN_PROCESS':
        return 'Pago pendiente';
      case 'CANCELED_BY_USER':
        return 'Cancelada a petición del usuario';
      default:
        return 'Sin estado';
    }
  };

  const getOrderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case 'ORDER_REJECTED' || 'ORDER_CANCELED' || 'CANCELED_BY_USER':
        return '#d62626';
      case 'ORDER_DELIVERED':
        return '#4a5782';
      default:
        return 'black';
    }
  };

  const onCopyEfectyPinToClipboard = (efectyPin) => {
    navigator.clipboard.writeText(efectyPin);
    notification.info({ message: 'PIN copiado al portapapeles' });
  };

  const onCopyOrderLink = (orderId) => orderId;

  const onCopyWompiToClipboard = (url) => url;

  const getPaymentInfoSection = () => {
    const { paymentInfo } = order;
    const { carrierCustomInfo } = paymentInfo;
    if (paymentInfo.name === 'CREDIBANCO') {
      return (
        <div>
          <button
            type="button"
            className="button linear-gradient-button"
            onClick={() => onOpenLink(carrierCustomInfo.formUrl)}
            style={{ height: '30px' }}
          >
            Pagar pedido
          </button>
        </div>
      );
    }
    if (paymentInfo.name === 'NEQUI') {
      return (
        <div className="paymentInstructions__container">
          <div
            style={{ textAlign: 'left', color: '#4A5782', fontWeight: '600', marginBottom: '10px' }}
          >
            Instrucciones de pago
          </div>
          <div style={{ textAlign: 'left' }}>
            Ingresa a Nequi con tu cuenta ({carrierCustomInfo.cellphone}) y aprueba el pago.
          </div>
        </div>
      );
    }
    if (paymentInfo.name === 'EFECTY') {
      return (
        <div className="paymentInstructions__container">
          <div
            style={{ textAlign: 'left', color: '#4A5782', fontWeight: '600', marginBottom: '10px' }}
          >
            Instrucciones de pago
          </div>
          <div style={{ textAlign: 'left' }}>
            Copia este pin y dirigete a cualquier punto efecty del país
          </div>

          <div style={{ textAlign: 'left', color: 'black', fontWeight: '600' }}>
            <div className="efectyPin__input">
              <span className="efectyPin__value">{carrierCustomInfo.pinEfecty}</span>
              <span
                className="copyPin__button"
                onClick={() => onCopyEfectyPinToClipboard(carrierCustomInfo.pinEfecty)}
                aria-hidden
              >
                <img src={CopyIconBlue} alt="" />
              </span>
            </div>
          </div>
          <div style={{ textAlign: 'left', color: 'black', fontSize: '12px' }}>
            Expira el {carrierCustomInfo.efectyExpirationDate}
          </div>
          <div style={{ textAlign: 'left', fontSize: '12px' }}>
            Código de Proyecto: 111627 - VALIENTA BOTON DE PAGO
          </div>
        </div>
      );
    }
    return null;
  };

  const onOpenLink = (url) => window.open(url, '_blank');

  const getOrderProductsSection = () => {
    const { suborders } = order;
    const orderProducts = [];
    suborders.forEach((suborder) => {
      const { suborderItems } = suborder;
      suborderItems.forEach((item) => orderProducts.push(item));
    });
    return orderProducts.map((product) => (
      <div className="order__product" key={product.id}>
        <div className="orderProduct__imageContainer">
          <img src={product.image || ImageUnavailable} className="orderProduct__image" alt="" />
        </div>
        <div className="orderProduct__productInfo">
          <div className="productName">{`${product.quantity} X ${product.name}`}</div>
          <div className="productConfiguration">{product.configuration}</div>
        </div>
      </div>
    ));
  };

  return (
    <div className="OrderCard">
      {order && order.id && (
        <div className="copyOrder__container">
          <CopyToClipboard
            text={onCopyOrderLink(order.id)}
            onCopy={() =>
              notification.info({ message: 'Número de pedido copiado en el portapapeles' })
            }
          >
            <div className="copyOrderLink" aria-hidden>
              <span>Copiar link de mi pedido</span>
              <img src={CopyIconBlue} className="copyIcon__small" alt="" />
            </div>
          </CopyToClipboard>
        </div>
      )}
      {/* Order status container */}
      <div className="section__card orderStatus__container">
        {isLastOrder && <div className="lastOrder__badge">Último pedido</div>}
        <div className="sectionCard__title">Estado de mi pedido</div>
        {isFetchingOrder && (
          <span style={{ marginRight: '10px' }}>
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: '20px', color: '#06C2AC' }}></LoadingOutlined>
              }
            ></Spin>
          </span>
        )}

        <span style={{ color: getOrderStatusColor(order.status.value) }}>
          {orderStatusToSpanish(order.status.value)}
        </span>
      </div>
      {/* Order payment info */}
      <div className="section__card paymentMethod__container">
        <div className="sectionCard__title">Método de pago</div>
        <div className="paymentMethod__label">
          <span>
            <img className="paymentMethod__icon" src={paymentMethod?.imgSrc} alt="Método de pago" />
          </span>
          <span className="paymentMethod__total">
            {paymentCarrierToLabel(order.paymentCarrier)}
          </span>
          {orderStatusPayment?.paymentInfo?.carrierCustomInfo?.paymentLink && (
            <CopyToClipboard
              text={onCopyWompiToClipboard(
                orderStatusPayment?.paymentInfo.carrierCustomInfo.paymentLink,
              )}
              onCopy={() => notification.info({ message: 'Link copiado al portapapeles' })}
            >
              <div className="paymentMethod__btn_copy">
                <img src={CopyIconBlue} alt="copy" />
                <span className="paymentMethod__btn_copy_text">COPIAR LINK DE PAGO</span>
              </div>
            </CopyToClipboard>
          )}
        </div>
        <div className="paymentMethod__total">Total: {toLocaleCurrency(order.totalToPay)}</div>

        {order.paymentInfo && order.status.value === 'PAYMENT_IN_PROCESS' && (
          <div className="orderPaymentInfo__container" style={{ marginTop: '10px' }}>
            {getPaymentInfoSection()}
          </div>
        )}
      </div>

      {/* Order shipping info */}
      <div className="section__card orderShipping__container">
        <div className="sectionCard__title">Dirección de entrega</div>
        <div>{order.shippingStreet1}</div>
        <div style={{ ...smallFontSize }}>{order.shippingStreet2}</div>
        {order.shippingIndications && (
          <div style={{ ...smallFontSize }}>{order.shippingIndications}</div>
        )}
      </div>
      <div className="section__card orderItems__container">
        <div className="sectionCard__title">Productos en el pedido</div>
        {getOrderProductsSection()}
        <div></div>
      </div>
    </div>
  );
}

OrderCard.propTypes = {
  order: PropTypes.any,
  orderStatusPayment: PropTypes.any,
  isFetchingOrder: PropTypes.bool,
  isLastOrder: PropTypes.bool,
};

const smallFontSize = { fontSize: '12px' };
