import React from 'react';
import './orderSummaryStep.scss';
import PropTypes from 'prop-types';

// Icons
import EditIconBlue from '../../../assets/icons/edit-blue.svg';
import Contraentrega from '../../../images/Contraentrega.svg';
import wompi from '../../../images/wompi.png';

export default function OrderSummaryStep({ shippingForm, paymentForm, handleEditStep }) {
  const getPaymentMethodIconSrc = () => {
    const { carrier } = paymentForm;
    if (carrier === 'ON_DELIVERY') return Contraentrega;
    if (carrier === 'WOMPI') return wompi;
    return null;
  };

  const getPaymentMethodLabel = () => {
    const { carrier } = paymentForm;
    if (carrier === 'ON_DELIVERY') return 'Pago contra entrega';
    if (carrier === 'WOMPI') return 'Wompi';
    return null;
  };

  return (
    <div className="OrderSummaryStep">
      {/* User info card */}
      <div className="form__card">
        <div
          className="formCard__editButtonContainer"
          onClick={() => handleEditStep(1)}
          aria-hidden
        >
          <span>Editar</span>
          <img src={EditIconBlue} alt="" className="formCard__editButton" />
        </div>
        <div className="formCard__title">Datos del cliente</div>
        <div className="formCard__info">
          <div>{`${shippingForm.firstName} ${shippingForm.lastName}`}</div>
          <div>{shippingForm.phone}</div>
        </div>
      </div>
      {/* Address info card */}
      <div className="form__card">
        <div
          className="formCard__editButtonContainer"
          onClick={() => handleEditStep(1)}
          aria-hidden
        >
          <span>Editar</span>
          <img src={EditIconBlue} alt="" className="formCard__editButton" />
        </div>
        <div className="formCard__title">Dirección de entrega</div>
        <div className="formCard__info">
          <div>{shippingForm.street1}</div>
          <div>{shippingForm.street2}</div>
        </div>
      </div>
      <div className="form__card">
        <div
          className="formCard__editButtonContainer"
          onClick={() => handleEditStep(2)}
          aria-hidden
        >
          <span>Editar</span>
          <img src={EditIconBlue} alt="" className="formCard__editButton" />
        </div>
        <div className="formCard__title">Método de pago</div>
        <div className="formCard__info paymentInfo">
          <span>
            <img className="paymentInfo__icon" src={getPaymentMethodIconSrc()} alt="" />
          </span>
          <span>{getPaymentMethodLabel()}</span>
        </div>
      </div>
    </div>
  );
}

OrderSummaryStep.propTypes = {
  shippingForm: PropTypes.any,
  paymentForm: PropTypes.any,
  handleEditStep: PropTypes.func,
};
