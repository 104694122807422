import React, { useEffect, useContext, useState } from 'react';
import './productDetail.scss';

// Navigation
import { useParams, useNavigate } from 'react-router-dom';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import BackButton from '../../components/BackButton/BackButton';
import ProductDetailMainCard from '../../components/ProductDetailCard/ProductDetailMainCard';
import { fetchProductById, fetchStore } from '../../gateway/store-service-api';
import { events, trackEvent } from '../../utils/trackEvent';
import NotFound from '../NotFound/NotFound';

export default function ProductDetail() {
  const navigate = useNavigate();
  const [context, dispatch] = useContext(Context);
  const [showView, setShowView] = useState(true);
  const params = useParams();
  useEffect(() => {
    async function fetchStoreInfo() {
      const response = await fetchStore();
      dispatch({ type: 'SET_STORE', store: response });
      fetchProduct(response.id);
    }
    async function fetchProduct(id) {
      const paramsProduct = await fetchProductById(id, params.productId);
      trackEvent(events.viewContent, { ...paramsProduct });
      dispatch({ type: 'SET_CURRENT_PRODUCT', currentProduct: paramsProduct });
      setShowView(!!paramsProduct);
    }
    if (!context.currentProduct || typeof context.currentProduct === 'undefined') {
      fetchStoreInfo();
    }
    return () => dispatch({ type: 'SET_CURRENT_PRODUCT', currentProduct: null });
  }, []);

  return (
    <div className="ProductDetail">
      <div style={{ marginBottom: '15px' }}>
        <BackButton
          handleGoBack={() => navigate('/tienda')}
          buttonText="Volver a la tienda"
        ></BackButton>
      </div>
      {showView && context.currentProduct ? (
        <ProductDetailMainCard product={context.currentProduct}></ProductDetailMainCard>
      ) : (
        !showView && <NotFound message="El producto que estás buscando no existe." />
      )}
    </div>
  );
}
