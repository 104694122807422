import React, { useContext, useEffect, useState, useRef } from 'react';
import './header.scss';

// Navigation
import { useNavigate, useLocation } from 'react-router-dom';

// Ant Components
import { Input } from 'antd';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import Filter from '../Filter/Filter';

const { Search } = Input;

export default function Header() {
  const [context, dispatch] = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();

  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  
  const searchInputRef = useRef(null);

  const isShowingCitySelector = () => !location.pathname.includes('checkout') && !isSearchBarOpen;

  const isShowingCartButton = () =>
    !location.pathname.includes('checkout') && !location.pathname.includes('carrito');

  const handleSearchTermSearch = (inputSearchTerm) => {
    dispatch({ type: 'SET_PAGE', page: 0 });
    dispatch({ type: 'SET_SEARCH_TERM', searchTerm: inputSearchTerm });
    
    if (searchInputRef.current) {
      searchInputRef.current.blur();
    }
  };

  const toggleSearchBar = () => {
    setIsSearchBarOpen((prev) => !prev);
  };

  return (
    <div className="Header">
      <div className="headerContent">
        <div className="leftButtons__container">
          <span
            className="menuButton__container"
            onClick={() => dispatch({ type: 'SET_IS_SHOWING_SIDE_NAV', isShowingSideNav: true })}
            aria-hidden
          >
            <ion-icon name="menu-outline" style={{ height: '30px', width: '30px' }}></ion-icon>
          </span>
        </div>
        <div className="rightButtons__container">
          {isShowingCartButton() && (
            <div className="headerButton" onClick={() => navigate('/carrito')} aria-hidden>
              <ion-icon name="cart-outline" style={iconStyle}></ion-icon>
              <div className="cartQuantity">{context.cartItems.length}</div>
            </div>
          )}

          {isSearchBarOpen ? (
            <div className="headerButton" onClick={toggleSearchBar} aria-hidden>
              <ion-icon name="close-outline" style={iconStyle}></ion-icon>
            </div>
          ) : (
            <div className="headerButton" onClick={toggleSearchBar} aria-hidden>
              <ion-icon name="search-outline" style={iconStyle}></ion-icon>
            </div>
          )}
          {isSearchBarOpen && (
            <div className="searchBar">
              <Search
                id="searchInput"
                onSearch={(value) => handleSearchTermSearch(value)}
                enterButton
                allowClear
                size="small"
                placeholder="Buscar..."
                ref={searchInputRef}
              />
            </div>
          )}

          {isShowingCitySelector() && (
            <div className="citySelector__container">
              <Filter
                value={context.destinationCity?.name || 'Ciudad de destino'}
                handleClick={() =>
                  dispatch({ type: 'SHOW_CITIES_MODAL', isShowingCitiesModal: true })
                }
              ></Filter>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const iconStyle = {
  minWidth: '25px',
  maxWidth: '25px',
  minHeight: '25px',
  maxHeight: '25px',
};
