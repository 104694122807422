import React, { useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Context } from '../../GlobalState/GlobalState';
import './BaseCarousel.scss';

export default function BaseCarousel({ children, carouselId, baseScrollQuantity = 300 }) {
  // Context
  const [context] = useContext(Context);

  // State
  const [isShowingLeftScrollButton, setIsShowingLeftScrollButton] = useState(true);
  const [isShowingRightScrollButton, setIsShowingRightScrollButton] = useState(true);

  const scrollableContentId = `scrollableContent__${carouselId}`;

  const onScroll = (isScrollRight) => {
    const scrollableContent = document.getElementById(scrollableContentId);
    let scrollQuantity = baseScrollQuantity;
    if (!isScrollRight) {
      scrollQuantity *= -1;
    }
    scrollableContent.scrollTo({
      top: 0,
      left: scrollableContent.scrollLeft + scrollQuantity,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const scrollableContent = document.getElementById(scrollableContentId);
    const { scrollLeft, scrollWidth, clientWidth } = scrollableContent;
    if (scrollLeft <= 0) {
      setIsShowingLeftScrollButton(false);
      setIsShowingRightScrollButton(true);
    } else if (scrollWidth - clientWidth - scrollLeft <= 0) {
      setIsShowingLeftScrollButton(true);
      setIsShowingRightScrollButton(false);
    } else {
      setIsShowingLeftScrollButton(true);
      setIsShowingRightScrollButton(true);
    }
  };

  const debouncedScrollHandler = useMemo(() => {
    debounce(handleScroll, 100);
  }, [isShowingLeftScrollButton, isShowingRightScrollButton]);

  useEffect(() => {
    const scrollableContent = document.getElementById(scrollableContentId);
    scrollableContent.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => scrollableContent.removeEventListener('scroll', handleScroll);
  }, [debouncedScrollHandler, isShowingRightScrollButton, isShowingLeftScrollButton]);

  return (
    <div className="BaseCarousel">
      {!context.isMobileDevice && isShowingLeftScrollButton && (
        <div
          className="carouselScrollButton left"
          onClick={() => onScroll(false)}
          aria-hidden="true"
        >
          <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
      )}

      <div className="scrollableContent" id={scrollableContentId}>
        {children}
      </div>

      {!context.isMobileDevice && isShowingRightScrollButton && (
        <div
          className="carouselScrollButton right"
          onClick={() => onScroll(true)}
          aria-hidden="true"
        >
          <ion-icon name="chevron-forward-outline"></ion-icon>
        </div>
      )}
    </div>
  );
}

BaseCarousel.propTypes = {
  children: PropTypes.any,
  carouselId: PropTypes.string,
  baseScrollQuantity: PropTypes.number,
};
