import React from 'react';
import './quantityButtons.scss';
import PropTypes from 'prop-types';

// Ant Components
import { notification } from 'antd';

export default function QuantityButtons({ handleQuantityChange, currentQuantity, maxQuantity }) {
  const onChangeQuantity = (newQuantity) => {
    if ((newQuantity > 0 && currentQuantity < maxQuantity) || newQuantity < 0) {
      handleQuantityChange(newQuantity);
    } else {
      notification.error({ message: 'Has alcanzado la cantidad máxima de unidades' });
    }
  };
  return (
    <div className="CartProductsCardQuantityButtons">
      <div
        className="changeQuantityButton quantity__button"
        onClick={() => onChangeQuantity(-1)}
        aria-hidden="true"
      >
        <ion-icon
          name={currentQuantity > 1 ? 'remove-outline' : 'trash-outline'}
          style={iconsSize}
        ></ion-icon>
      </div>
      <div className="changeQuantityButton">{currentQuantity}</div>
      <div
        className="changeQuantityButton quantity__button"
        onClick={() => onChangeQuantity(1)}
        aria-hidden="true"
      >
        <ion-icon name="add-outline" style={iconsSize}></ion-icon>
      </div>
    </div>
  );
}

QuantityButtons.propTypes = {
  handleQuantityChange: PropTypes.func,
  currentQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
};

const iconsSize = {
  minWidth: '18px',
  maxWidth: '18px',
  minHeight: '18px',
  maxHeight: '18px',
};
