import React, { useState, useEffect, useRef } from 'react';
import { notification } from 'antd';
import './shippingInfoStep.scss';
import PropTypes from 'prop-types';

// External components
import Multiselect from '../../../components/Multiselect/Multiselect';

export default function ShippingInfoStep({
  shippingForm,
  handleShippingInfoChange,
  destinationCity,
  shippingFormErrors,
  shippingFormUserErrors,
}) {
  const [addressState, setAddressState] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    addressDetails: '',
    additionalInfo: '',
  });

  const addressPrefixesPossibleValues = [
    'Calle',
    'Carrera',
    'Avenida',
    'Autopista',
    'Diagonal',
    'Manzana',
    'Transversal',
    'Anillo',
    'Zona',
    'Circular',
    'Ingresar otra dirección',
  ];

  function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
      if (didMountRef.current) {
        return fn();
      }
      didMountRef.current = true;
      return null;
    }, inputs);
  }

  useEffect(() => {
    let newAddressState = { ...addressState };
    if (shippingForm.street1) {
      const splittedStreet1Info = shippingForm.street1.split(' ');
      if (
        splittedStreet1Info.length === 6 &&
        splittedStreet1Info[0] !== 'Ingresar otra dirección'
      ) {
        newAddressState = {
          ...newAddressState,
          field1: splittedStreet1Info[0],
          field2: splittedStreet1Info[1],
          field3: splittedStreet1Info[3],
          field4: splittedStreet1Info[5],
        };
      } else {
        newAddressState = {
          ...newAddressState,
          field1: 'Ingresar otra dirección',
          field5: shippingForm.street1,
        };
      }
    }
    if (shippingForm.street2) {
      newAddressState = {
        ...newAddressState,
        addressDetails: shippingForm.street2,
      };
    }
    if (shippingForm.indications) {
      newAddressState = { ...newAddressState, additionalInfo: shippingForm.indications };
    }
    setAddressState(newAddressState);
  }, [shippingForm]);

  useEffect(() => {
    if (shippingFormErrors.length) {
      notification.error({
        message: 'Errores de validación',
        duration: 10,
        description: `Faltan los siguientes datos:\n ${shippingFormUserErrors.join(', ')}`,
        style: { width: 300 },
      });
    }
  }, [shippingFormErrors]);

  useDidUpdateEffect(() => {
    const { field1, field2, field3, field4, field5 } = addressState;
    let street1 = '';
    if (field1 !== 'Ingresar otra dirección') {
      if (field2.length && field3.length && field4.length) {
        street1 = `${field1.replace(/ /g, '')} ${field2.replace(/ /g, '')} # ${field3.replace(
          / /g,
          '',
        )} - ${field4.replace(/ /g, '')}`;
      } else {
        street1 = '';
      }
    } else {
      street1 = field5;
    }
    handleShippingInfoChange({
      ...shippingForm,
      street1,
    });
  }, [
    addressState.field1,
    addressState.field2,
    addressState.field3,
    addressState.field4,
    addressState.field5,
  ]);

  useDidUpdateEffect(() => {
    handleShippingInfoChange({
      ...shippingForm,
      street2: addressState.addressDetails,
    });
  }, [addressState.addressDetails]);

  useDidUpdateEffect(() => {
    const { additionalInfo } = addressState;
    handleShippingInfoChange({ ...shippingForm, indications: additionalInfo });
  }, [addressState.additionalInfo]);

  const isError = (errorName) => shippingFormErrors.find((value) => value === errorName);

  const isPhoneLengthError = () =>
    shippingForm.phone.length > 0 &&
    (shippingForm.phone.length < 10 || shippingForm.phone.length > 10);

  return (
    <div className="ShippingInfoStep">
      <div className="form__card">
        <div className="formCard__title">Datos del comprador</div>
        <div style={{ marginBottom: '20px' }}>
          Ingresa tus datos, o si es un regalo, los de la persona a quien le enviarás el pedido.
          Asegurate de que esté bien escrita antes de continuar.
        </div>
        {/* First name row */}
        <div className="row no-padding">
          <div className="col no-padding">
            <div className="input__label">Nombres</div>
            <input
              className={isError('firstName') ? 'error' : ''}
              type="text"
              id="firstName"
              placeholder="Ingresa tus nombres"
              onChange={(e) =>
                handleShippingInfoChange({ ...shippingForm, firstName: e.target.value })
              }
              value={shippingForm.firstName}
            />
            {isError('firstName') && <div className="error__label">Este campo es obligatorio</div>}
          </div>
        </div>
        {/* Last name row */}
        <div className="row no-padding" style={{ marginTop: '15px' }}>
          <div className="col no-padding">
            <div className="input__label">Apellidos</div>
            <input
              className={isError('lastName') ? 'error' : ''}
              type="text"
              id="lastName"
              placeholder="Ingresa tus apellidos"
              onChange={(e) =>
                handleShippingInfoChange({ ...shippingForm, lastName: e.target.value })
              }
              value={shippingForm.lastName}
            />
            {isError('lastName') && <div className="error__label">Este campo es obligatorio</div>}
          </div>
        </div>
        {/* Cellphone row */}
        <div className="row no-padding" style={{ marginTop: '15px' }}>
          <div className="col no-padding">
            <div className="input__label">Celular</div>
            <input
              className={isError('phone') ? 'error' : ''}
              type="number"
              id="cellphoneNumber"
              placeholder="Ingresa tu número de celular"
              onChange={(e) => handleShippingInfoChange({ ...shippingForm, phone: e.target.value })}
              value={shippingForm.phone}
            />
            {isPhoneLengthError() && (
              <div className="error__label">El número debe contener 10 caracteres</div>
            )}
            {isError('phone') && <div className="error__label">Este campo es obligatorio</div>}
          </div>
        </div>
      </div>
      {/* Shipping info card */}
      <div className="form__card" style={{ marginTop: '20px' }}>
        <div className="formCard__title">Dirección de entrega (máximo 100 caracteres)</div>
        <div style={{ marginBottom: '20px' }}>
          Ingresa la dirección a la cual quieres que enviemos el pedido. Asegurate de que esté bien
          escrita antes de continuar.
        </div>
        {/* Cellphone row */}
        <div className="row no-padding">
          <div className="col no-padding">
            <div className="input__label">Ciudad</div>
            <input className="disabled" type="text" value={destinationCity.name} disabled />
          </div>
        </div>
        {/* Address row */}
        <div className="row no-padding" style={{ marginTop: '30px' }}>
          <div className="col no-padding">
            <div className="input__label">Dirección</div>
            <div className="row no-padding address__row">
              {addressState.field1 !== 'Ingresar otra dirección' ? (
                <div className="normalAddress__container">
                  <div className="addressMultiselect">
                    <Multiselect
                      values={addressPrefixesPossibleValues}
                      onSelectValue={(value) => setAddressState({ ...addressState, field1: value })}
                      selectedValue={addressState.field1}
                    ></Multiselect>
                  </div>
                  <input
                    className={isError('address') ? 'error' : ''}
                    id="addressField2"
                    type="text"
                    style={{ width: '115px' }}
                    onChange={(e) => setAddressState({ ...addressState, field2: e.target.value })}
                    value={addressState.field2}
                  />
                  <div className="address__decorator">#</div>
                  <input
                    className={isError('address') ? 'error' : ''}
                    id="addressField3"
                    type="text"
                    style={{ width: '115px' }}
                    onChange={(e) => setAddressState({ ...addressState, field3: e.target.value })}
                    value={addressState.field3}
                  />
                  <div className="address__decorator">-</div>
                  <input
                    className={isError('address') ? 'error' : ''}
                    id="addressField4"
                    type="text"
                    style={{ width: '115px' }}
                    onChange={(e) => setAddressState({ ...addressState, field4: e.target.value })}
                    value={addressState.field4}
                  />
                  {isError('address') && (
                    <div className="error__label">Completa todos los campos de la dirección</div>
                  )}
                </div>
              ) : (
                <div className="normalAddress__container">
                  <div className="addressMultiselect">
                    <Multiselect
                      values={addressPrefixesPossibleValues}
                      onSelectValue={(value) => setAddressState({ ...addressState, field1: value })}
                      selectedValue={addressState.field1}
                    ></Multiselect>
                  </div>
                  <input
                    className={isError('address') ? 'error' : ''}
                    type="text"
                    value={addressState.field5}
                    onChange={(e) => setAddressState({ ...addressState, field5: e.target.value })}
                    style={{ width: '100%' }}
                    placeholder="Ej. Calle 23A #32-55"
                    maxLength={100}
                  ></input>
                  {isError('address') && (
                    <div className="error__label">Este campo es obligatorio</div>
                  )}
                </div>
              )}
              {isError('longAddress') && (
                <div className="error__label">Este campo debe contener máximo 100 caracteres</div>
              )}
            </div>
          </div>
        </div>
        {/* Address details row */}
        <div className="row no-padding" style={{ marginTop: '30px' }}>
          <div className="col no-padding">
            <div className="input__label">Detalles de la dirección (máximo 50 caracteres)</div>
            <input
              className={isError('addressDetails') ? 'error' : ''}
              type="text"
              placeholder="Ej. Torre 2 Apartamento 201"
              onChange={(e) => setAddressState({ ...addressState, addressDetails: e.target.value })}
              value={addressState.addressDetails}
              maxLength={50}
            />
            {isError('addressDetails') && (
              <div className="error__label">Este campo es obligatorio</div>
            )}
            {isError('longAddressDetail') && (
              <div className="error__label">Este campo debe contener máximo 50 caracteres</div>
            )}
          </div>
        </div>
        {/* Address additional info row */}
        <div className="row no-padding" style={{ marginTop: '30px' }}>
          <div className="col no-padding">
            <div className="input__label">Información adicional (máximo 50 caracteres)</div>
            <input
              type="text"
              placeholder="Ej. Conjunto Bellavista"
              onChange={(e) => setAddressState({ ...addressState, additionalInfo: e.target.value })}
              value={addressState.additionalInfo}
              maxLength={50}
            />
            {isError('longIndications') && (
              <div className="error__label">Este campo debe contener máximo 50 caracteres</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ShippingInfoStep.propTypes = {
  shippingForm: PropTypes.any,
  handleShippingInfoChange: PropTypes.func,
  destinationCity: PropTypes.any,
  shippingFormErrors: PropTypes.array,
  shippingFormUserErrors: PropTypes.array,
};
