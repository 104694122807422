import { notification } from 'antd';
export default function displayErrorMessage(title, e, aditionalInfo) {
  if (e.response) {
    const error = e.response;
    if (error.status === 404) {
      notification.error({ message: title, duration: 30, description: `404: URL Invalida` });
    } else if (error.status !== 403 && error.status !== 401) {
      notification.error({
        message: title,
        duration: 10,
        description: `${translateError(error.data.errorCode, error.data.message, aditionalInfo)}`,
        style: { width: 300 },
      });
    }
  } else {
    notification.error({
      message: `Error desconocido en ${title}`,
      duration: 30,
      description: `${e.message}`,
    });
  }
}

function translateError(code, message, extraInfo) {
  let toSpanish = message;
  if (code === 'STORE_NOT_FOUND') {
    toSpanish = `No pudimos encontrar la tienda con URL: ${extraInfo}`;
  } else if (code === 'ADDRESS_NOT_COVERED_BY_CARRIER') {
    toSpanish =
      'La ciudad destino no es cubierta por la transportadora en el método de entrega seleccionado.';
  } else if (code === 'SELLER_ADDRESSES_NOT_FOUND') {
    toSpanish = 'No es posible realizar pedidos en esta tienda en este momento.';
  }
  return toSpanish;
}
