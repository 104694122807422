import * as Sentry from '@sentry/browser';
const ls = require('local-storage');

Sentry.init({
  dsn: 'https://36f2ec1f364c4f159cdd6eb2981cee22@sentry.tools.vertical.com.co/51',
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: `${process.env.REACT_APP_VERSION}`,
  tracesSampleRate: 1.0,
});

export function configureInfoSentry() {
  const storeInfo = sessionStorage.getItem('store') !== 'undefined' ? JSON.parse(sessionStorage.getItem('store')) : null;
  Sentry.configureScope((scope) => {
    const shippingForm = ls.get('shippingForm');
    const checkoutCurrentStep = ls.get('checkoutCurrentStep');
    const store = storeInfo;
    const paymentForm = ls.get('paymentForm');
    if (shippingForm) {
      scope.setUser({ id: shippingForm.phone });
    }
    scope.setContext('contexto', {
      shippingForm,
      checkoutCurrentStep,
      store: { id: store?.id, name: store?.name },
      paymentForm,
      path: window.location.href,
    });
    return scope;
  });
}
