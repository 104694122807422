import React from 'react';
import './quantityButton.scss';

import PropTypes from 'prop-types';

export default function QuantityButton({
  quantity,
  handleQuantityChange,
  minQuantity = 0,
  maxQuantity = 100,
}) {
  const onChangeQuantity = (value) => {
    if ((value < 0 && quantity > minQuantity) || (value > 0 && quantity < maxQuantity)) {
      handleQuantityChange(value);
    }
  };
  return (
    <div className="QuantityButton">
      <div
        className="button__container minus"
        onClick={() => onChangeQuantity(-1)}
        aria-hidden="true"
      >
        <ion-icon name="remove-outline" style={quantityButtonStyle}></ion-icon>
      </div>
      <div className="quantity__container">{quantity}</div>
      <div
        className="button__container plus"
        onClick={() => onChangeQuantity(1)}
        aria-hidden="true"
      >
        <ion-icon name="add-outline" style={quantityButtonStyle}></ion-icon>
      </div>
    </div>
  );
}

QuantityButton.propTypes = {
  quantity: PropTypes.number,
  handleQuantityChange: PropTypes.func,
  minQuantity: PropTypes.number,
  maxQuantity: PropTypes.number,
};

const quantityButtonStyle = {
  minWidth: '22px',
  maxWidth: '22px',
  minHeight: '22px',
  maxHeight: '22px',
};
