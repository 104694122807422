import React from 'react';
import PropTypes from 'prop-types';
import './modalBase.scss';

import OverlayLoader from '../OverlayLoader/OverlayLoader';

export default class ModalBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="modal">
        {this.props.isVisible && (
          <div className="modalOverlay">
            <div className={`modalGeneralContainer ${this.props.size}`}>
              {this.props.isLoading && <OverlayLoader></OverlayLoader>}
              <div className="modalHeader">
                <h1>{this.props.title}</h1>
                {this.props.showCloseButton && (
                  <div className="closeIcon" onClick={this.props.onClose} aria-hidden="true">
                    <ion-icon
                      name="close-outline"
                      style={{ height: '25px', width: '25px' }}
                    ></ion-icon>
                  </div>
                )}
              </div>
              <div className="modalBody">{this.props.children}</div>
              <div className="modalFooter">{this.props.footer}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ModalBase.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  footer: PropTypes.any,
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
};
