import React, { useEffect, useContext } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import './mainLayout.scss';

// External components
import Header from '../components/Header/Header';
import ModalSelectCity from '../components/ModalSelectCity/ModalSelectCity';
import SideNav from '../components/SideNav/SideNav';

// Global State
import { Context } from '../GlobalState/GlobalState';
import { fetchShippingCost } from '../gateway/shipping-service-api';

const ls = require('local-storage');

export default function MainLayout({ children }) {
  const [context, dispatch] = useContext(Context);
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    if (currentParams.from === 'nequi') {
      dispatch({ type: 'SET_WORKINNEQUI', workInNequi: true });
      const shippingForm = {
        cityCode: '',
        firstName: currentParams.name,
        lastName: currentParams.surname,
        phone: currentParams.cellphone,
        phoneCode: '+57',
        street1: '',
        street2: '',
        indications: '',
      };
      ls.set('shippingForm', shippingForm);
    } else dispatch({ type: 'SET_WORKINNEQUI', workInNequi: false });
  }, [searchParams]);

  useEffect(() => {
    if (location.pathname !== '/tienda/productos') {
      const viewsContainer = document.getElementById('viewsContainer');
      if (viewsContainer) {
        viewsContainer.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  }, [location]);

  const setWindowWidthListener = () => {
    window.addEventListener('resize', onWindowResize);
  };

  const onWindowResize = () => {
    dispatch({ type: 'IS_MOBILE_DEVICE', isMobileDevice: window.innerWidth <= 768 });
  };

  useEffect(() => {
    onWindowResize();
    setWindowWidthListener();
    if (!context.destinationCity) {
      dispatch({ type: 'SHOW_CITIES_MODAL', isShowingCitiesModal: true });
    }
    return () => window.removeEventListener('resize', onWindowResize);
  }, []);

  useEffect(() => {
    if (context.destinationCity) {
      fetchShippingCost(context.destinationCity).then((shippingCost) => {
        dispatch({ type: 'SET_SHIPPING_COST', shippingCost });
      });
    }
  }, []);

  const onCitySelect = (city) => {
    dispatch({ type: 'SET_DESTINATION_CITY', destinationCity: city });
  };

  return (
    <div className="MainLayout">
      <Header></Header>
      <SideNav></SideNav>
      {context.isViewsOverlay && <div className="views__overlay"></div>}
      <div
        id="viewsContainer"
        className={`views__container ${context.isViewsOverlay ? 'lock' : ''}`}
      >
        {children}
      </div>
      {context.isShowingCitiesModal && (
        <ModalSelectCity
          closeModal={() => dispatch({ type: 'SHOW_CITIES_MODAL', isShowingCitiesModal: false })}
          handleCityChange={onCitySelect}
        ></ModalSelectCity>
      )}
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.any,
};
