import React, { useContext, useEffect, useState } from 'react';
import './paymentInfoStep.scss';
import PropTypes from 'prop-types';

// Context
import { Context } from '../../../GlobalState/GlobalState';

// API
import { validateRoute } from '../../../gateway/shipping-service-api';
import CheckoutPaymentMethod from '../../../components/Checkout/CheckoutPaymentMethod/CheckoutPaymentMethod';

// Utilities
import {
  getAvailablePaymentMethods,
  getPaymentMethodByCarrierAndCarrierParams,
} from './paymentMethods';

export default function PaymentInfoStep({ handlePaymentInfoChange, paymentForm }) {
  const [context] = useContext(Context);
  const [isOnDeliveryPaymentAvailable, setIsOnDeliveryPaymentAvailable] = useState(false);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);

  const validateOnDeliveryPaymentMethod = async () => {
    const { carriersWithOnDeliveryPayment } = await validateRoute(
      context.destinationCity?.governmentCode,
    );
    if (carriersWithOnDeliveryPayment.length) {
      setIsOnDeliveryPaymentAvailable(true);
    }
  };

  useEffect(() => {
    validateOnDeliveryPaymentMethod();
    if (!paymentForm.carrier.length) {
      setCurrentPaymentMethod({ name: '', value: '' });
    } else {
      const foundPaymentMethod = getPaymentMethodByCarrierAndCarrierParams(paymentForm);
      setCurrentPaymentMethod({ ...foundPaymentMethod });
    }
  }, []);

  useEffect(() => {
    if (currentPaymentMethod) {
      handlePaymentInfoChange(currentPaymentMethod);
    }
  }, [currentPaymentMethod]);

  return (
    <div className="PaymentInfoStep">
      <div className="form__card">
        <div className="formCard__title">Métodos de pago</div>
        <div className="paymentMethods__container">
          {getAvailablePaymentMethods(isOnDeliveryPaymentAvailable).map((paymentMethod) => (
            <div className="paymentMethod__container" key={paymentMethod.name}>
              <CheckoutPaymentMethod
                paymentMethod={paymentMethod}
                isCurrentMethod={paymentMethod.name === currentPaymentMethod?.name}
                handleMethodClick={setCurrentPaymentMethod}
              ></CheckoutPaymentMethod>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

PaymentInfoStep.propTypes = {
  handlePaymentInfoChange: PropTypes.func,
  paymentForm: PropTypes.any,
};
