import React, { useContext } from 'react';
import './sideNav.scss';

// Navigation
import { useNavigate } from 'react-router-dom';

// Context
import { Context } from '../../GlobalState/GlobalState';

// Icons
import XIconWhite from '../../assets/icons/x-white.svg';

export default function SideNav() {
  const [context, dispatch] = useContext(Context);
  const navigate = useNavigate();

  const onNavigate = (pathName) => {
    dispatch({ type: 'SET_IS_SHOWING_SIDE_NAV', isShowingSideNav: false });
    navigate(pathName);
  };

  return (
    <div className={`SideNav ${context.isShowingSideNav ? 'active' : ''}`}>
      <div className="sideNav__header">
        <span className="header__storeName">{context.store?.name}</span>
        <span
          className="sideNav__closeButton"
          onClick={() => dispatch({ type: 'SET_IS_SHOWING_SIDE_NAV', isShowingSideNav: false })}
          aria-hidden
        >
          <img src={XIconWhite} alt="" />
        </span>
      </div>
      <div className="sideNav__buttonsContainer">
        <div className="sideNavButtonsContainer__title">Herramientas</div>
        <div className="sideNavButton" onClick={() => onNavigate('/mi-orden')} aria-hidden>
          <span className="sideNavButton__iconContainer">
            <ion-icon name="search-outline" style={{ height: '18px', width: '18px' }}></ion-icon>
          </span>
          <span className="sideNavButton__label">Consultar estado de pedido</span>
        </div>
      </div>
    </div>
  );
}
