import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import Reducer from './Reducer';

const ls = require('local-storage');
const storeInfo = sessionStorage.getItem('store') !== 'undefined' ? JSON.parse(sessionStorage.getItem('store')) : null;
const initialState = {
  searchTerm: '',
  isMobileDevice: false,
  workInNequi: false,
  loadingStore: true,
  store: storeInfo,
  cities: [],
  destinationCitySearchTerm: 'Bogotá, D.C',
  destinationCity: ls.get('destinationCity') || null,
  inventoryHash: {},
  isShowingCitiesModal: false,
  currentProduct: null,
  isViewsOverlay: false,
  isShowingSideNav: false,
  lastOrderId: ls.get('lastOrderId') || null,
  shippingCost: undefined,
  storeCategories: [],
  categoryId: undefined,

  shouldFetchProducts: true,
  loadingProducts: true,
  page: 0,
  totalPages: 10,
  products: [],

  currentProductInfo: {
    product: undefined,
    options: {},
    keys: [],
  },
  currentProductConfigInfo: {
    optionsBeingSelected: {},
    productConfiguration: undefined,
  },
  currentProductConfigDesiredQuantity: 1,

  cartItems: ls.get('cartItems') ? ls.get('cartItems') : [],
  loadingOrderDryRun: false,
  orderDryRun: {},
  shouldCreateOrder: false,
  loadingCreateOrder: false,
  shippingCarriers: [],
  orderStatusAndPaymentInfo: {
    status: { value: 'ORDER_CREATED' },
    paymentCarrier: undefined,
    paymentInfo: undefined,
  },
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export const Context = createContext(initialState);
Store.propTypes = {
  children: PropTypes.any,
};
export default Store;
