import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import './productDetailMainCard.scss';

// Ant components
import { notification } from 'antd';

// Context
import { Context } from '../../GlobalState/GlobalState';

// External components
import ImageViewer from '../ImageViewer/ImageViewer';
import ProductDetailCardProductDimensions from './ProductDetailCardProductDimensions/ProductDetailCardProductDimensions';
import QuantityButton from '../QuantityButton/QuantityButton';
import OverlayLoader from '../OverlayLoader/OverlayLoader';
import ShowHideText from '../ShowHideText/ShowHideText';

// API
import { fetchInventory } from '../../gateway/warehouse-service-api';

// Helpers
import {
  getProductDimensionsByProductsConfigurations,
  getDefaultValueForDimension,
  getProductConfigurationBySelectedVariations,
  getProductPriceWithShippingCost,
  productConfigurationHasDiscount,
} from '../../utilities/products_helper';
import { events, trackEvent } from '../../utils/trackEvent';

export default function ProductDetailMainCard({ product }) {
  const [context, dispatch] = useContext(Context);
  const [productForm, setProductForm] = useState({
    city: {
      id: 'b2e1ea34-55c2-464c-a236-3e93c73933e3',
      name: 'Bogotá, D.C.',
      governmentCode: '11001000',
    },
    shippingCost: 10900,
    priceVariation: 0,
    quantity: 1,
    isEditingPrice: false,
  });
  const [productDimensions, setProductDimensions] = useState(null);
  const [selectedDimensionsValues, setSelectedDimensionValues] = useState(null);
  const [currentProductConfiguration, setCurrentProductConfiguration] = useState(null);
  const [stock, setStock] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstImage, setFirstImage] = useState('');
  // const [categories, setCategories] = useState('');
  const [description, setDescription] = useState('');
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (product && product.product) {
      const resultProductDimensions = getProductDimensionsByProductsConfigurations(
        product.product.productConfigurations,
      );
      setProductDimensions(resultProductDimensions);
      setFirstImage(product.product.productMedia[0].mediaURL);
      // setCategories(product.product.categories[0].name);
      setDescription(context.currentProduct.description.slice(0, 150));
      setUrl(`https://${context.store.url}flipcat.store/tienda/productos/${product.id}`);
    }
  }, [product.product]);

  useEffect(() => {
    const newSelectedDimensions = new Map();
    if (productDimensions) {
      const productDimensionsKeys = Array.from(productDimensions.keys());
      productDimensionsKeys.forEach((dimensionKey) => {
        const defaultValue = getDefaultValueForDimension(productDimensions, dimensionKey);
        newSelectedDimensions.set(dimensionKey, defaultValue);
      });
      setSelectedDimensionValues(newSelectedDimensions);
    }
  }, [productDimensions]);

  useEffect(() => {
    if (selectedDimensionsValues) {
      const foundProductConfiguration = getProductConfigurationBySelectedVariations(
        product.product.productConfigurations,
        selectedDimensionsValues,
      );
      setCurrentProductConfiguration(foundProductConfiguration);
    }
  }, [selectedDimensionsValues]);

  useEffect(async () => {
    if (currentProductConfiguration) {
      setIsLoading(true);
      try {
        const inventory = await fetchInventory(
          [currentProductConfiguration.id],
          context.destinationCity?.governmentCode,
        );
        const totalStock = inventory.reduce((accum, item) => {
          const sum = accum + item.stock;
          return sum;
        }, 0);
        setStock(totalStock);
        setProductForm({ ...productForm, priceVariation: 0 });
      } catch (error) {
        console.error('error :>> ', error);
      } finally {
        setIsLoading(false);
      }
    } else setStock(0);
  }, [currentProductConfiguration, context.destinationCity]);

  const onSelectVariation = (dimensionKey, value) => {
    const copyOfSelectedValuesMap = new Map(selectedDimensionsValues);
    copyOfSelectedValuesMap.set(dimensionKey, value);
    trackEvent(events.customizeProduct, { key: dimensionKey, value: value.value });
    setSelectedDimensionValues(copyOfSelectedValuesMap);
  };

  const handleProductQuantityChange = (value) => {
    const newValue = productForm.quantity + value;
    setProductForm({ ...productForm, quantity: newValue });
  };

  const onAddProductToShoppingCart = async () => {
    setIsLoading(true);

    const cartItem = {
      product: {
        name: product.name,
        reference: product.product.reference,
        productMedia: product.product.productMedia,
      },
      productConfiguration: { ...currentProductConfiguration },
      quantity: productForm.quantity,
      companyName: product.product.catalog.company.name,
    };
    dispatch({ type: 'ADD_PRODUCT', cartItem });
    notification.success({ message: 'Producto agregado' });

    setIsLoading(false);
  };

  const areAllCostsFetched = context.store && context.shippingCost;

  const canShowProdConfig = areAllCostsFetched && currentProductConfiguration;

  return (
    <div className="ProductDetailMainCard">
      <Helmet>
        <title>{context.currentProduct.name.slice(0, 65)}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={context.currentProduct.name.slice(0, 35)} />
        <meta property="og:description" content={description.slice(0, 65)} />
        <meta property="og:image:alt" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" itemProp="image" content={firstImage} />
        <meta property="og:image:secure_url" content={firstImage} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="200" />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={context.store.url} />
        <link rel="canonical" href={url}></link>
      </Helmet>
      {isLoading && <OverlayLoader></OverlayLoader>}
      {product && product.product && (
        <div className="mainCard__grid">
          {/* Image column */}
          <div className="col no-padding images__column">
            {product.product.productMedia && product.product.productMedia.length && (
              <div className="imagesViewer__container">
                <ImageViewer
                  images={product.product.productMedia}
                  imageUrlKey="mediaURL"
                ></ImageViewer>
              </div>
            )}
          </div>
          {/* Info column */}
          <div className="info__column">
            <div className="col no-padding" style={{ marginBottom: '10px' }}>
              <div className="productDetail__productName">{product.name}</div>
              <div className="productDetail__reference">Ref. {product.product.reference}</div>
            </div>
            <div className="row no-padding" style={{ marginBottom: '20px' }}>
              {canShowProdConfig && productConfigurationHasDiscount(currentProductConfiguration) ? (
                <span className="productDetail__productPriceContainer">
                  <span className="basePrice discounted">
                    {getProductPriceWithShippingCost(
                      currentProductConfiguration.retailPrice,
                      context.store.isShippingPriceIncluded,
                      context.shippingCost,
                    )}
                  </span>
                  <span className="discountedPrice">
                    {getProductPriceWithShippingCost(
                      currentProductConfiguration.discount.discountedRetailPrice,
                      context.store.isShippingPriceIncluded,
                      context.shippingCost,
                    )}
                  </span>
                </span>
              ) : (
                <span className="productDetail__productPriceContainer">
                  <span className="basePrice">
                    {canShowProdConfig &&
                      getProductPriceWithShippingCost(
                        currentProductConfiguration.retailPrice,
                        context.store.isShippingPriceIncluded,
                        context.shippingCost,
                      )}
                    {!currentProductConfiguration &&
                      getProductPriceWithShippingCost(
                        product.lowestPrice,
                        context.store.isShippingPriceIncluded,
                        context.shippingCost,
                      )}
                  </span>
                </span>
              )}
            </div>
            <div className="col no-padding" style={{ marginBottom: '10px' }}>
              <div style={{ marginBottom: '20px' }}>Descripción:</div>
              <div>
                <ShowHideText text={product.description}></ShowHideText>
              </div>
            </div>
            {/* Dimensions selector row */}
            {productDimensions && selectedDimensionsValues && (
              <div className="row no-padding">
                <div>
                  <ProductDetailCardProductDimensions
                    productDimensions={productDimensions}
                    selectedDimensionsValues={selectedDimensionsValues}
                    onSelectVariation={onSelectVariation}
                  ></ProductDetailCardProductDimensions>
                </div>
              </div>
            )}
            {/* Stock row */}
            <div className="row no-padding" style={{ marginBottom: '20px' }}>
              {stock > 0 ? (
                <span className="available__units">
                  Unidades disponibles: <strong>{stock}</strong>
                </span>
              ) : (
                <span className="available__units noStock">No hay unidades disponibles</span>
              )}
            </div>
            {/* Quantity button and cart button row */}
            <div className="row no-padding center" style={{ flexWrap: 'nowrap' }}>
              <span className="quantityButton__container">
                <QuantityButton
                  quantity={productForm.quantity}
                  handleQuantityChange={handleProductQuantityChange}
                  minQuantity={1}
                  maxQuantity={stock}
                ></QuantityButton>
              </span>
              <span className="addCartButton__container">
                <button
                  type="button"
                  className={`button primary addCartButton ${stock < 1 ? 'disabled' : ''}`}
                  onClick={() => onAddProductToShoppingCart()}
                  disabled={stock < 1}
                >
                  Agregar al carrito
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

ProductDetailMainCard.propTypes = {
  product: PropTypes.any,
};
