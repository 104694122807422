import React, { useState } from 'react';
import './multiselect.scss';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

export default function Multiselect({
  values,
  selectedValue,
  valueKey,
  isInput,
  placeholder,
  onInputValue,
  onSelectValue,
  inputType,
}) {
  const [isShowingValues, setIsShowingValues] = useState(false);
  const toggling = () => setIsShowingValues(!isShowingValues);
  const onSelectOption = (value) => {
    onSelectValue(value);
    toggling();
  };
  return (
    <div className="multiselect">
      <div className="main__selector" onMouseDown={toggling} aria-hidden="true">
        {isInput ? (
          <input
            type="text"
            className={`selectedValue__input ${inputType}`}
            value={selectedValue}
            onChange={(e) => onInputValue(e.target.value)}
            placeholder={placeholder}
          />
        ) : (
          <div className={`selectedValue__input ${inputType}`}>{selectedValue || placeholder}</div>
        )}
        <div className="toggle__button">
          <ion-icon
            name={isShowingValues ? 'chevron-up-outline' : 'chevron-down-outline'}
          ></ion-icon>
        </div>
      </div>
      {isShowingValues && values && (
        <div className="values__container">
          {values.map((value) => (
            <div
              className="value"
              key={uuidv4()}
              onMouseDown={() => onSelectOption(value)}
              aria-hidden="true"
            >
              {valueKey ? value[valueKey] : value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

Multiselect.propTypes = {
  values: PropTypes.array,
  selectedValue: PropTypes.any,
  valueKey: PropTypes.string,
  isInput: PropTypes.bool,
  placeholder: PropTypes.string,
  onInputValue: PropTypes.func,
  onSelectValue: PropTypes.func,
  inputType: PropTypes.string,
};
