import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './imageViewer.scss';

export default function ImageViewer({ images, imageUrlKey }) {
  const [currentImage, setCurrentImage] = useState([]);
  useEffect(() => {
    if (images.length) setCurrentImage(images[0]);
  }, []);
  return (
    <div className="ImageViewer">
      <div className="verticalImagesPicker">
        {images.map((image) => (
          <div
            className={`imagePreview__container ${currentImage.id === image.id && 'current'}`}
            onClick={() => setCurrentImage(image)}
            aria-hidden="true"
            key={image.id}
          >
            <img src={image[imageUrlKey]} alt="" className="imagePreview" />
          </div>
        ))}
      </div>
      <div className="currentImageContainer">
        {currentImage && currentImage[imageUrlKey] && (
          <img src={currentImage[imageUrlKey]} alt="" className="currentImage"></img>
        )}
      </div>
    </div>
  );
}

ImageViewer.propTypes = {
  images: PropTypes.array,
  imageUrlKey: PropTypes.string,
};
