import React from 'react';
import './styles/App.scss';
import GlobalState from './GlobalState/GlobalState';
import Router from './router/Router';
import { configureInfoSentry } from './configSentry';

const ls = require('local-storage');

function verifyVersion() {
  const APP_VERSION = ls.get('APP_VERSION');
  if (!APP_VERSION || APP_VERSION !== process.env.REACT_APP_VERSION) {
    ls.set('APP_VERSION', process.env.REACT_APP_VERSION);
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    verifyVersion();
    this.setApiGatewayURL();
    configureInfoSentry();
  }

  setApiGatewayURL() {
    const storeServiceApi = process.env.REACT_APP_STORE_SERVICE_API;
    const gatewayServiceApi = process.env.REACT_APP_GATEWAY_SERVICE_API;
    ls.set('storeServiceApi', storeServiceApi);
    ls.set('gatewayServiceApi', gatewayServiceApi);
  }

  render() {
    return (
      <div className="App">
        <GlobalState>
          <Router></Router>
        </GlobalState>
      </div>
    );
  }
}

export default App;
