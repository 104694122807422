import React from 'react';
import { Outlet } from 'react-router-dom';

export default function SellerStoreMain() {
  return (
    <div className="SellerStoreMain">
      <Outlet></Outlet>
    </div>
  );
}
