import { productConfigurationHasDiscount } from './products_helper';

export function getProductsTotals(cartItems, shippingCost) {
  const response = {
    baseTotal: 0,
    discountedTotal: 0,
    sellerTotal: 0,
    retailTotal: 0,
  };
  cartItems.forEach((cartItem) => {
    const { productConfiguration, quantity } = cartItem;
    if (productConfigurationHasDiscount(productConfiguration)) {
      response.discountedTotal +=
        (productConfiguration.discount.discountedRetailPrice + shippingCost) * quantity;
    } else {
      response.discountedTotal += (productConfiguration.retailPrice + shippingCost) * quantity;
    }
    response.baseTotal += (productConfiguration.retailPrice + shippingCost) * quantity;
    response.sellerTotal += productConfiguration.sellerPrice * quantity;
    response.retailTotal += productConfiguration.retailPrice * quantity + shippingCost;
  });
  return response;
}
