import displayErrorMessage from './displayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchInventory = async (productConfigurationIds, destinyCityCode) => {
  if (productConfigurationIds.length === 0) {
    return [];
  }
  let url = `${ls.get('gatewayServiceApi')}/warehouse-service-api/public/inventory`;
  url += `?productConfigurationIds=${JSON.stringify(productConfigurationIds)}`;

  if (destinyCityCode) url += `&destinyCityCode=${destinyCityCode}`;
  try {
    const result = await axios({ method: 'get', url });
    return result.data;
  } catch (e) {
    displayErrorMessage('Error recuperando el inventario', e);
  }
  return [];
};
