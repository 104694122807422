import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, Redirect } from 'react-router-dom';

// API Services
import { fetchStore } from '../gateway/store-service-api';

import MainLayout from '../layout/MainLayout';
import SellerStoreSkeleton from '../components/SellerStoreSkeleton/SellerStoreSkeleton';

import Product from '../components/Product/Product';
import SellerStore from '../views/SellerStore/SellerStore';
import ProductDetail from '../views/ProductDetail/ProductDetail';
import SellerStoreMain from '../views/SellerStore/SellerStoreMain';

import Cart from '../views/Cart/Cart';
import Checkout from '../views/Checkout/Checkout';
import OrderStatus from '../views/OrderStatus/OrderStatus';

import NotFound from '../views/NotFound/NotFound';

import { Context } from '../GlobalState/GlobalState';

import { configureMeta } from '../utils/configureMeta';
import { configureGtag } from '../utils/configureGtag';

export default function Router() {
  const [isLoading, setIsLoading] = useState(false);
  const [context, dispatch] = useContext(Context);
  const [showApp, setShowApp] = useState(true);
  useEffect(() => {
    let isMounted = true;
    async function fetchStoreInformation() {
      if (isMounted) {
        setIsLoading(true);
        const store = await fetchStore();
        dispatch({ type: 'SET_STORE', store });
        setShowApp(!!store.id);
        setIsLoading(false);

        return store;
      }
      return null;
    }

    fetchStoreInformation().then((data) => {
      if (data.googleTagId) {
        configureGtag(data.googleTagId);
      }
      if (data.metaPixelId) {
        configureMeta(data.metaPixelId);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  let componentToRender = (
    <Routes>
      <Route path="*" element={<NotFound></NotFound>} />
      <Route exact path="/" element={<Navigate to="/tienda"></Navigate>}></Route>
      <Route path="/producto/:productId" element={<Product></Product>} />
      <Route path="/carrito" element={<Cart></Cart>} />
      <Route path="/checkout" element={<Checkout></Checkout>} />
      <Route path="/mi-orden" element={<OrderStatus></OrderStatus>}></Route>
      <Route path="/tienda" element={<SellerStoreMain></SellerStoreMain>}>
        <Route index element={<Navigate to="productos"></Navigate>}></Route>
        <Route index path="productos" element={<SellerStore></SellerStore>}></Route>
        <Route path="productos/:productId" element={<ProductDetail></ProductDetail>}></Route>
        <Route path="*" element={<Navigate to="productos"></Navigate>}></Route>
      </Route>
    </Routes>
  );
  if (!showApp) {
    componentToRender = (
      <Routes>
        <Route path="*" element={<NotFound message={`La tienda ${window.location.host} no existe.`}/>} />
      </Routes>
    );
  }
  return (
    <BrowserRouter>
      <MainLayout>{isLoading ? <SellerStoreSkeleton /> : componentToRender}</MainLayout>
    </BrowserRouter>
  );
}
