import React, { useContext, useEffect } from 'react';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Divider, Button, Input, Skeleton, Carousel, Row, Col, Radio } from 'antd';
import { toCurrency, capitalize } from '../../utils/formatters';
import { getOptions } from '../../utils/products';
import { fetchProductById } from '../../gateway/store-service-api';
import { fetchInventory } from '../../gateway/warehouse-service-api';
import { AddProductToCart } from '../Cart/Cart';
import { Context } from '../../GlobalState/GlobalState';
import { events, trackEvent } from '../../utils/trackEvent';

function changeOptions(e, product, optionsBeingSelected) {
  // eslint-disable-next-line no-param-reassign
  optionsBeingSelected[e.target.name] = e.target.value;
  let productConfiguration = {};
  const { productConfigurations } = product.product;
  for (let i = 0; i < productConfigurations.length; i += 1) {
    const { productConfigurationValues, isAvailable } = productConfigurations[i];
    let isThisVariant = isAvailable;
    let variantsChecked = 0;
    for (let j = 0; isThisVariant && j < productConfigurationValues.length; j += 1) {
      const propertyToEval = productConfigurationValues[j];
      const { value } = propertyToEval.dimensionValue;
      const { name } = propertyToEval.dimensionValue.dimension;
      variantsChecked += 1;
      if (optionsBeingSelected[name] !== value) {
        isThisVariant = false;
      }
    }
    if (isThisVariant && variantsChecked === productConfigurationValues.length) {
      productConfiguration = productConfigurations[i];
    }
  }
  return { selectedProductConfiguration: productConfiguration, optionsBeingSelected };
}

function getLabelLogoStyle(isMobile) {
  const dimension = isMobile
    ? { width: '35px', height: '35px', marginLeft: '10px' }
    : { width: '60px', height: '60px', marginLeft: '100px' };
  return {
    ...dimension,
    borderRadius: '100%',
    position: 'absolute',
    zIndex: '10',
  };
}

function getDescription(product, isMobile) {
  return (
    <div style={isMobile ? {} : { textAlign: 'left', marginLeft: '5%' }}>
      <div style={isMobile ? { margin: '5% 10%' } : { margin: '5% 10% 2% 10%' }}>Descripción:</div>
      <pre
        style={{
          fontWeight: '100',
          fontSize: '14px',
          lineHeight: '17.57px',
          fontFamily: 'Lato',
          marginLeft: '10%',
          marginRight: '10%',
          textAlign: 'justify',
          whiteSpace: 'pre-wrap',
        }}
      >
        {!product && <Skeleton active />}
        {product && product.description}
      </pre>
    </div>
  );
}

const Product = () => {
  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = window.innerWidth < 500;
  const [state, dispatch] = useContext(Context);

  useEffect(async () => {
    if (!state.currentProductInfo.product && state.store.id) {
      const fetchedProduct = await fetchProductById(state.store.id);
      if (fetchedProduct) {
        const options = getOptions(fetchedProduct);
        const keys = Object.keys(options);
        dispatch({
          type: 'SET_PRODUCT_INFO',
          currentProductInfo: { product: fetchedProduct, options, keys },
        });
      }
    }
  }, [state.store.id]);

  // Update inventory for the current product
  useEffect(async () => {
    if (state.currentProductInfo.product && state.destinationCity.cityCode) {
      const productConfigIds = [];
      const productConfigs = state.currentProductInfo.product.product.productConfigurations;
      for (let j = 0; j < productConfigs.length; j += 1) {
        productConfigIds.push(productConfigs[j].id);
      }

      const inventory = await fetchInventory(productConfigIds, state.destinationCity.cityCode);

      const groupedInventory = {};

      for (let i = 0; i < inventory.length; i += 1) {
        const { productConfigurationId, stock } = inventory[i];

        if (!groupedInventory[productConfigurationId]) {
          groupedInventory[productConfigurationId] = 0;
        }
        groupedInventory[productConfigurationId] += stock;
      }
      for (let i = 0; i < inventory.length; i += 1) {
        const { productConfigurationId } = inventory[i];
        state.inventoryHash[productConfigurationId] = groupedInventory[productConfigurationId];
      }
      dispatch({ type: 'SET_INVENTORY', inventoryHash: state.inventoryHash });
    }
  }, [state.destinationCity.cityCode]);

  const { product, keys, options } = state.currentProductInfo;
  const { productConfiguration } = state.currentProductConfigInfo;
  const addShippingPrice = state.store.isShippingPriceIncluded ? 10900 : 0;

  return (
    <div style={{ marginTop: '6.5em' }}>
      <div style={{ textAlign: 'center', width: '100%' }}>
        <Row gutter={24}>
          <Col span={isMobile ? '24' : '12'}>
            <Carousel autoplay>
              {product &&
                product.product.productMedia.map((item) => (
                  <div
                    key={item.id}
                    style={{ textAlign: 'center', width: 'auto', position: 'relative' }}
                  >
                    <img
                      style={getLabelLogoStyle(isMobile)}
                      src={product.product.catalog.company.labelLogo}
                      alt="LabelLogo Img"
                    />
                    <img
                      src={item.mediaURL
                        .replace('.jpg', `${isMobile ? '_500x500' : '_1080x1080'}.jpg`)
                        .replace('.png', `${isMobile ? '_500x500' : '_1080x1080'}.png`)}
                      alt="Img"
                      style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: 'auto',
                        maxWidth: isMobile ? '80%' : '40%',
                        maxHeight: isMobile ? '80%' : '350px',
                        borderRadius: '12px ',
                        textAlign: 'center',
                        position: 'relative',
                      }}
                    />
                    {product.product.isDisabled && (
                      <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          top: '10px',
                          zIndex: 10,
                          backgroundColor: '#E56564',
                          padding: '7px',
                          fontSize: '0.8em',
                          color: 'white',
                        }}
                      >
                        No disponible
                      </div>
                    )}
                  </div>
                ))}
            </Carousel>
            {!isMobile && getDescription(product, isMobile)}
          </Col>
          <Col span={isMobile ? '24' : '12'} style={{ textAlign: 'left' }}>
            <div
              style={{
                fontSize: '16px',
                fontFamily: 'Lato',
                margin: '5% 10% 0% 10%',
                fontWeight: '300',
                color: '#484848',
                lineHeight: '20px',
              }}
            >
              {product && product.name}
            </div>
            <div
              style={{
                fontSize: '14px',
                margin: '1% 10%',
                fontWeight: '600',
                color: '#484848',
              }}
            >
              Referencia: {product && product.product.reference}
            </div>
            <div
              style={{
                fontSize: '14px',
                fontFamily: 'Lato',
                margin: '1% 10%',
                fontWeight: '700',
                color: '#484848',
              }}
            >
              Precio:
              {product &&
                !state.selectedVariant &&
                toCurrency(product.product.productConfigurations[0].retailPrice + addShippingPrice)}
              {state.selectedVariant &&
                !state.selectedVariant.id &&
                toCurrency(product.product.productConfigurations[0].retailPrice + addShippingPrice)}
              {state.selectedVariant &&
                state.selectedVariant.id &&
                toCurrency(state.selectedVariant.retailPrice + addShippingPrice)}
            </div>
            {isMobile && getDescription(product, isMobile)}
            <div style={{ margin: '5% 10%' }}>
              {keys.map((item) => (
                <div key={item}>
                  <Row>Selecciona {capitalize(item)}:</Row>
                  <br />
                  <Row>
                    <Radio.Group
                      name={item}
                      options={options[item]}
                      onChange={(e) => {
                        const { selectedProductConfiguration, optionsBeingSelected } =
                          changeOptions(
                            e,
                            product,
                            state.currentProductConfigInfo.optionsBeingSelected,
                          );
                        dispatch({
                          type: 'SET_PRODUCT_CONFIG_INFO',
                          currentProductConfigInfo: {
                            productConfiguration: selectedProductConfiguration,
                            optionsBeingSelected,
                          },
                        });
                      }}
                    />
                  </Row>
                  <br />
                </div>
              ))}
            </div>
            <Divider />
            <div
              style={{
                fontSize: '13px',
                fontFamily: 'Lato',
                margin: '0% 10%',
                fontWeight: '700',
                color: '#484848',
                lineHeight: '20px',
              }}
            >
              {productConfiguration && productConfiguration.id
                ? `Unidades disponibles: ${state.inventoryHash[productConfiguration.id] || 0}`
                : 'Elige una configuración para ver disponibilidad'}
            </div>

            <div>
              <div
                style={{
                  fontSize: '16px',
                  fontFamily: 'Lato',
                  marginLeft: '10%',
                  marginRight: '10%',
                  fontWeight: '300',
                  color: '#484848',
                  lineHeight: '20px',
                }}
              >
                Elige la cantidad
              </div>

              <br />
              <div style={{ marginLeft: '10%', marginRight: '10%' }}>
                <div style={{ display: 'inline-flex' }}>
                  <Button
                    style={{ width: '20%' }}
                    onClick={() =>
                      dispatch({
                        type: 'SET_PRODUCT_CONFIG_INFO_DESIRED_QUANTITY',
                        currentProductConfigDesiredQuantity:
                          state.currentProductConfigDesiredQuantity === 0
                            ? 0
                            : state.currentProductConfigDesiredQuantity - 1,
                      })
                    }
                  >
                    <MinusOutlined />
                  </Button>
                  <Input
                    style={{ width: '80%' }}
                    value={state.currentProductConfigDesiredQuantity}
                    className="width"
                  />
                  <Button
                    style={{ width: '20%' }}
                    onClick={() =>
                      dispatch({
                        type: 'SET_PRODUCT_CONFIG_INFO_DESIRED_QUANTITY',
                        currentProductConfigDesiredQuantity: Math.min(
                          state.currentProductConfigDesiredQuantity + 1,
                          state.inventoryHash[productConfiguration.id] || 0,
                        ),
                      })
                    }
                  >
                    <PlusOutlined />
                  </Button>
                </div>
              </div>
            </div>
            <AddProductToCart
              productConfiguration={productConfiguration}
              product={product?.product}
              quantity={state.currentProductConfigDesiredQuantity}
            />
            <Divider />
          </Col>
        </Row>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Product;
