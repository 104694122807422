import React, { useEffect, useState } from 'react';
import './orderStatus.scss';

// Navigation
import { useSearchParams, useNavigate } from 'react-router-dom';

// API
import { getOrderById, getOrderStatusById } from '../../gateway/order-service-api';

// External components
import BackButton from '../../components/BackButton/BackButton';
import OrderCard from '../../components/OrderCard/OrderCard';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

// Icons
import TrackOrdersBoxImage from '../../assets/icons/box-search-order.svg';

const ls = require('local-storage');

export default function OrderStatus() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [title, setTitle] = useState('Consultar estado de tu pedido');
  const [orderStatus, setOrderStatus] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState({
    isFetchingOrder: false,
    isFetchingOrderStatus: false,
  });
  const [searchForm, setSearchForm] = useState({ orderId: '' });

  useEffect(() => {
    const orderId = searchParams.get('orderId');
    const status = searchParams.get('status');
    if (orderId) {
      setTitle(
        status === 'success' ? 'Pedido creado exitosamente' : 'Consultar estado de tu pedido',
      );
      fetchOrder(orderId);
    }
  }, [searchParams]);

  const fetchOrder = async (orderId) => {
    setLoadingStatus({ ...loadingStatus, isFetchingOrder: true });
    let orderStatusAndPaymentInfo = await getOrderById(orderId);
    let orderStatusResponse = await getOrderStatusById(orderId);
    setOrder(orderStatusAndPaymentInfo);
    setOrderStatus(orderStatusResponse || {});
    setLoadingStatus({ ...loadingStatus, isFetchingOrder: false });
    if (orderStatusAndPaymentInfo) {
      while (orderStatusAndPaymentInfo.status.value === 'ORDER_CREATED') {
        setLoadingStatus({ ...loadingStatus, isFetchingOrderStatus: true });
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 2000));
        // eslint-disable-next-line no-await-in-loop
        orderStatusAndPaymentInfo = await getOrderById(orderId);
        // eslint-disable-next-line no-await-in-loop
        orderStatusResponse = await getOrderStatusById(orderId);
      }
      setOrderStatus(orderStatusResponse || {});
      setOrder(orderStatusAndPaymentInfo);
      setLoadingStatus({ ...loadingStatus, isFetchingOrderStatus: false });
    }
    ls.set('lastOrderId', orderStatusAndPaymentInfo?.id);
  };

  const onSearchOrder = (orderId) => {
    navigate({ pathname: '/mi-orden', search: `?orderId=${orderId}` });
  };

  return (
    <div className="OrderStatus">
      {loadingStatus.isFetchingOrder && <OverlayLoader></OverlayLoader>}
      <div className="backButton__container">
        <BackButton
          buttonText="Volver a la tienda"
          handleGoBack={() => navigate('/tienda')}
        ></BackButton>
      </div>
      <div className="orderStatus__contentContainer">
        <div className="orderInfoContainer">
          <div className="searchOrder__contaner">
            <img src={TrackOrdersBoxImage} className="trackOrdersBox__image" alt="" />
            <div className="orderStatus__title">{title}</div>
            <div className="searchInput__label">
              El tiempo de entrega y estado de tu pedido te llegarán a tu WhatsApp o copia el link
              de tu pedido y rastréalo en el buscador
            </div>
            <div className="searchInput__container">
              <input
                type="text"
                placeholder="ID de tu pedido"
                className="searchOrder__input"
                value={searchForm.orderId}
                onChange={(e) => setSearchForm({ ...searchForm, orderId: e.target.value })}
              />
              <button
                type="button"
                className={`button primary searchOrder__button ${
                  searchForm.orderId.length === 0 ? 'disabled' : ''
                }`}
                onClick={() => onSearchOrder(searchForm.orderId)}
              >
                <span className="searchIcon__container">
                  <ion-icon name="search-outline"></ion-icon>
                </span>
                <span>Buscar</span>
              </button>
            </div>
            {ls.get('lastOrderId') && ls.get('lastOrderId') !== order?.id && (
              <div
                className="lastOrder__container"
                onClick={() => onSearchOrder(ls.get('lastOrderId'))}
                aria-hidden
              >
                Ver mi último pedido
              </div>
            )}
          </div>
          <div className="orders__container">
            {order && (
              <OrderCard
                order={order}
                orderStatusPayment={orderStatus}
                isFetchingOrder={loadingStatus.isFetchingOrderStatus}
                isLastOrder={ls.get('lastOrderId') === order?.id}
              ></OrderCard>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
