import React from 'react';
import PropTypes from 'prop-types';
import './notFound.scss';
NotFound.propTypes = {
  message: PropTypes.string,
};
export default function NotFound({ message }) {
  const text = message || 'La página que estás buscando no existe.';
  return (
    <div className="error-container">
      <h1 className="error-title">404</h1>
      <p className="error-message">{text}</p>
      {/* Puedes agregar un enlace para volver a la página de inicio u otra página */}
    </div>
  );
}
