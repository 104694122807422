import displayErrorMessage from './displayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const getOrderById = async (orderId) => {
  const url = `${ls.get(
    'gatewayServiceApi',
  )}/order-service-api/public/order/${orderId}/clientOrder`;
  try {
    const { data } = await axios({ method: 'GET', url });
    return data;
  } catch (error) {
    displayErrorMessage(`Error al obtener la orden con id: ${orderId}`, error);
  }
  return null;
};

export const getOrderStatusById = async (orderId) => {
  const url = `${ls.get('gatewayServiceApi')}/order-service-api/public/order/${orderId}/status`;

  try {
    const result = await axios({ method: 'GET', url });
    const order = result.data;
    return order;
  } catch (e) {
    displayErrorMessage('Error recuperando la orden', e);
  }
  return undefined;
};
