import React from 'react';
import './sellerStoreSkeleton.scss';

import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';

export default function SellerStoreSkeleton() {
  return (
    <div className="SellerStoreSkeleton">
      <div className="row no-padding">
        <SkeletonLoader height="200px" width="100%"></SkeletonLoader>
      </div>
      <div className="row no-wrap" style={{ columnGap: '20px' }}>
        <SkeletonLoader height="80px" width="80px" borderRadius="50%"></SkeletonLoader>
        <div style={{ width: '100%' }} className="info__skeleton">
          <SkeletonLoader height="20px" width="200px" borderRadius="10px"></SkeletonLoader>
          <SkeletonLoader height="20px" width="400px" borderRadius="10px"></SkeletonLoader>
        </div>
      </div>
    </div>
  );
}
