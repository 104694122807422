import displayErrorMessage from './displayErrorMessage';

const ls = require('local-storage');
const axios = require('axios');

export const fetchCities = async (cityName) => {
  let url = `${ls.get('gatewayServiceApi')}/company-service-api/public/city`;
  url += `?page=0&pageSize=10&cityName=${cityName}`;
  try {
    const result = await axios({ method: 'get', url });
    return result.data;
  } catch (e) {
    displayErrorMessage('Error recuperando las ciudades', e);
  }
  return [];
};
