import React from 'react';
import PropTypes from 'prop-types';
import './productDetailCardProductDimensions.scss';

export default function ProductDetailCardProductDimensions({
  productDimensions,
  selectedDimensionsValues,
  onSelectVariation,
}) {
  const isCurrentValue = (dimensionKey, valueId) => {
    const searchValue = selectedDimensionsValues.get(dimensionKey);
    return searchValue && searchValue.key === valueId;
  };

  const getProductDimensions = () => {
    const productDimensionsKeys = Array.from(productDimensions.keys());
    return productDimensionsKeys.map((dimensionName) => (
      <div className="dimension__container" key={dimensionName} style={{ marginBottom: '10px' }}>
        <h4>{`Selecciona ${dimensionName.toLowerCase()}`}</h4>
        <div className="possibleValues__container">{getProductDimensionValues(dimensionName)}</div>
      </div>
    ));
  };

  const getProductDimensionValues = (dimensionName) => {
    const possibleValuesMap = productDimensions.get(dimensionName);
    const possibleValuesKeys = Array.from(possibleValuesMap.keys());
    return possibleValuesKeys.map((valueId) => (
      <div
        className={`possibleValue ${isCurrentValue(dimensionName, valueId) && 'active'}`}
        key={valueId}
        onClick={() =>
          onSelectVariation(dimensionName, { key: valueId, value: possibleValuesMap.get(valueId) })
        }
        aria-hidden="true"
      >
        <span className="possibleValue__container">
          <span className="possibleValue__label">{possibleValuesMap.get(valueId)}</span>
        </span>
      </div>
    ));
  };

  return getProductDimensions();
}

ProductDetailCardProductDimensions.propTypes = {
  productDimensions: PropTypes.any,
};
