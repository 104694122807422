import React, { useEffect, useState, useContext } from 'react';
import './checkoutSummary.scss';
import PropTypes from 'prop-types';

// Context
import { Context } from '../../../GlobalState/GlobalState';

// External components

// Helpers
import { toLocaleCurrency } from '../../../utilities/currency_helper';
import { getProductsTotals } from '../../../utilities/cart_helper';

export default function CheckoutSummary({
  orderDryRun,
  currentStep,
  onMainButtonClick,
  shippingCost,
}) {
  const [context] = useContext(Context);
  const [summaryForm, setSummaryForm] = useState({
    baseTotal: 0,
    discountedTotal: 0,
  });

  useEffect(() => {
    const { baseTotal, discountedTotal } = getProductsTotals(context.cartItems, 0);
    setSummaryForm({
      ...summaryForm,
      baseTotal,
      discountedTotal,
    });
  }, [context.cartItems]);

  const getCurrentStepButtonLabel = () => {
    if (currentStep === 1) return 'Continuar';
    if (currentStep === 2) return 'Continuar';
    return 'Crear pedido';
  };

  const getLabelTotal = () => {
    if (currentStep === 1) return 'Subtotal';
    return 'Total';
  };

  const getTotalToPay = () => {
    if (currentStep === 1) return orderDryRun.totalProductCosts;
    return orderDryRun.totalProductCosts + shippingCost;
  };

  const getDiscount = () =>
    toLocaleCurrency(Math.abs(getTotalToPay() - summaryForm.discountedTotal));

  return (
    <div className="CheckoutSummary">
      <div className="checkoutSummary__summaryCard">
        <div className="summary__title">Resumen de costos</div>
        <div className="summary__row">
          <div className="summary__label">Precio de productos</div>
          <div className="summary__value">
            {toLocaleCurrency(orderDryRun?.totalProductCosts || 0)}
          </div>
        </div>
        {currentStep !== 1 && (
          <div className="summary__row">
            <div className="summary__label">+ Costo de envío</div>
            <div className="summary__value">
              {context.store.isShippingPriceIncluded
                ? 'Gratis'
                : toLocaleCurrency(orderDryRun && shippingCost)}
            </div>
            {context.store.isShippingPriceIncluded}
          </div>
        )}
        {context.store.isShippingPriceIncluded && (
          <div className="summary__row">
            <div className="summary__label">Descuento</div>
            <div className="summary__value">{orderDryRun && `- ${getDiscount()}`}</div>
          </div>
        )}
        <hr style={{ margin: '0' }} />
        <div className="summary__row">
          <div className="summary__label total">{getLabelTotal()}</div>
          <div className="summary__value total">
            {orderDryRun && toLocaleCurrency(getTotalToPay())}
          </div>
        </div>
        <button
          type="button"
          className="button primary"
          style={{ width: '100%' }}
          onClick={() => onMainButtonClick()}
        >
          {getCurrentStepButtonLabel()}
        </button>
      </div>
    </div>
  );
}

CheckoutSummary.propTypes = {
  orderDryRun: PropTypes.any,
  currentStep: PropTypes.number,
  onMainButtonClick: PropTypes.func,
  shippingCost: PropTypes.number,
};
