import React, { useState, useEffect, useContext } from 'react';
import './cartSummary.scss';

// Navigation
import { useNavigate } from 'react-router-dom';

// Helpers
import { toLocaleCurrency } from '../../utilities/currency_helper';
import { getProductsTotals } from '../../utilities/cart_helper';

// Context
import { Context } from '../../GlobalState/GlobalState';

export default function CartSummary() {
  const navigate = useNavigate();
  const [context] = useContext(Context);
  const { shippingCost } = context;
  const [summaryForm, setSummaryForm] = useState({
    baseTotal: 0,
    discountedTotal: 0,
  });

  useEffect(() => {
    const temporalShippingCost = context.store.isShippingPriceIncluded ? shippingCost : 0;
    const { baseTotal, discountedTotal } = getProductsTotals(
      context.cartItems,
      temporalShippingCost,
    );
    setSummaryForm({
      ...summaryForm,
      baseTotal,
      discountedTotal,
    });
  }, [context.cartItems, context.store, context.shippingCost]);

  return (
    <div className="CartSummary">
      <div className="cartSummary__title">Resumen de costos</div>
      <div className="summary__row">
        <span className="summaryRow__label">Precio de productos</span>
        {summaryForm.baseTotal ? (
          <span className="summaryRow__value">
            {summaryForm.baseTotal !== summaryForm.discountedTotal ? (
              <span className="summaryValue__discountContainer">
                <span className="originalValue">{toLocaleCurrency(summaryForm.baseTotal)}</span>
                <span className="discountedValue">
                  {toLocaleCurrency(summaryForm.discountedTotal)}
                </span>
              </span>
            ) : (
              <span>{toLocaleCurrency(summaryForm.baseTotal)}</span>
            )}
          </span>
        ) : null}
      </div>
      {context.store.isShippingPriceIncluded && (
        <div className="summary__row">
          <span className="summaryRow__label">+ Costo de envío</span>
          <span className="summaryRow__value">Gratis</span>
        </div>
      )}

      {/* <hr style={{ margin: '0' }} /> */}
      {!context.isMobileDevice && (
        <button className="button primary" type="button" onClick={() => navigate('/checkout')}>
          Continuar con el pago
        </button>
      )}
    </div>
  );
}
