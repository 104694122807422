import { act } from 'react-dom/test-utils';
import { events, trackEvent } from '../utils/trackEvent';

const ls = require('local-storage');

const Reducer = (state, action) => {
  switch (action.type) {
    // Nequi
    case 'SET_WORKINNEQUI':
      return {
        ...state,
        workInNequi: action.workInNequi,
      };
    // Store
    case 'SET_STORE':
      sessionStorage.setItem('store', JSON.stringify(action.store));
      return {
        ...state,
        store: action.store,
        loadingStore: false,
      };
    case 'SET_INVENTORY':
      return { ...state, inventoryHash: action.inventoryHash };
    case 'SET_DESTINATION_CITY_SEARCH_TERM':
      return { ...state, destinationCitySearchTerm: action.destinationCitySearchTerm };
    case 'SET_CITIES':
      return { ...state, cities: action.cities };
    case 'SET_DESTINATION_CITY':
      ls.set('destinationCity', action.destinationCity);
      return { ...state, destinationCity: action.destinationCity };
    case 'SET_SHIPPING_COST':
      return { ...state, shippingCost: action.shippingCost };
    case 'ADD_STORE_CATEGORIES':
      return {
        ...state,
        storeCategories: action.storeCategories,
      };
    case 'CHANGE_CATEGORY_ID':
      return {
        ...state,
        categoryId: action.categoryId,
        page: 0,
        products: [],
        shouldFetchProducts: true,
      };

    case 'SET_IS_VIEWS_OVERLAY':
      return { ...state, isViewsOverlay: action.isViewsOverlay };

    case 'SET_IS_SHOWING_SIDE_NAV':
      return {
        ...state,
        isViewsOverlay: action.isShowingSideNav,
        isShowingSideNav: action.isShowingSideNav,
      };

    case 'SET_LAST_ORDER_ID':
      ls.set('lastOrderId', action.lastOrderId);
      return { ...state, lastOrderId: action.lastOrderId };

    // Products:
    case 'SET_CURRENT_PRODUCT':
      return { ...state, currentProduct: action.currentProduct };
    case 'ADD_PRODUCTS':
      return {
        ...state,
        products: action.products,
        loadingProducts: false,
      };
    case 'SET_TOTAL_PAGES':
      return { ...state, totalPages: action.totalPages };
    case 'SET_PAGE':
      return { ...state, page: action.page, shouldFetchProducts: true };
    case 'SET_PRODUCTS_LOADING':
      return { ...state, loadingProducts: action.loadingProducts };
    case 'SET_SHOULD_FETCH_PRODUCTS':
      return { ...state, shouldFetchProducts: action.shouldFetchProducts };
    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.searchTerm, shouldFetchProducts: true };

    // Product
    case 'SET_PRODUCT_INFO':
      return { ...state, currentProductInfo: action.currentProductInfo };
    case 'SET_PRODUCT_CONFIG_INFO':
      return { ...state, currentProductConfigInfo: action.currentProductConfigInfo };
    case 'SET_PRODUCT_CONFIG_INFO_DESIRED_QUANTITY':
      return {
        ...state,
        currentProductConfigDesiredQuantity: action.currentProductConfigDesiredQuantity,
      };

    // Cart:
    case 'ADD_PRODUCT': {
      let alreadyExist = false;
      for (let i = 0; i < state.cartItems.length; i += 1) {
        const cartItem = state.cartItems[i];
        if (cartItem.productConfiguration.id === action.cartItem.productConfiguration.id) {
          alreadyExist = true;
          cartItem.quantity += action.cartItem.quantity;
        }
      }
      const newCartItems = alreadyExist
        ? [...state.cartItems]
        : state.cartItems.concat(action.cartItem);
      ls.set('cartItems', newCartItems);
      trackEvent(events.addToCart, { ...action.cartItem });
      return {
        ...state,
        cartItems: newCartItems,
      };
    }
    case 'REMOVE_PRODUCT':
      ls.set(
        'cartItems',
        state.cartItems.filter(
          (cartItem) => cartItem.productConfiguration.id !== action.productConfigurationId,
        ),
      );
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.productConfiguration.id !== action.productConfigurationId,
        ),
      };
    case 'EMPTY_CART':
      ls.set('cartItems', []);
      return { ...state, cartItems: [] };

    // Order:
    case 'SET_ORDER_DRY_RUN':
      return {
        ...state,
        loadingOrderDryRun: action.loadingOrderDryRun,
        orderDryRun: action.orderDryRun,
      };
    case 'SHOW_DRYRUN_ERROR':
      return { ...state, showDryRunError: action.showDryRunError };
    case 'SET_CLIENT_NAMES':
      return { ...state, clientName: action.clientName };
    case 'SET_CLIENT_SURNAMES':
      return { ...state, clientSurnames: action.clientSurnames };
    case 'SET_CLIENT_CELLPHONE':
      return { ...state, clientCellphone: action.clientCellphone };
    case 'SET_CLIENT_STREET_1':
      return { ...state, street_1: action.street_1 };
    case 'SET_CLIENT_ADDRESS_IS_FREE_FORM':
      return { ...state, isFreeForm: action.isFreeForm };
    case 'SET_CLIENT_STREET_TYPE':
      return { ...state, street_type: action.street_type };
    case 'SET_CLIENT_STREET_TYPE_1':
      return { ...state, street_type_1: action.street_type_1 };
    case 'SET_CLIENT_STREET_TYPE_2':
      return { ...state, street_type_2: action.street_type_2 };
    case 'SET_CLIENT_STREET_TYPE_3':
      return { ...state, street_type_3: action.street_type_3 };
    case 'SET_CLIENT_STREET_2':
      return { ...state, street_2: action.street_2 };
    case 'SET_CLIENT_STREET_INDICATIONS':
      return { ...state, street_indications: action.street_indications };
    case 'SET_CLIENT_PAYMENT_METHOD':
      return { ...state, clientPaymentMethod: action.clientPaymentMethod };
    case 'SET_CLIENT_SHIPMENT_CARRIER_ID':
      return { ...state, clientShipmentCarrierId: action.clientShipmentCarrierId };
    case 'SET_SHIPPING_CARRIERS':
      return { ...state, shippingCarriers: action.shippingCarriers };
    case 'SHOW_INCOMPLETE_REQUIRED_FIELDS':
      return { ...state, showIncompleteRequiredFields: action.showIncompleteRequiredFields };

    case 'SET_CREATE_ORDER_LOADING':
      return { ...state, loadingCreateOrder: action.loadingCreateOrder };
    case 'SET_SHOW_MODAL_ORDER_CREATED':
      return {
        ...state,
        showModalOrderCreated: action.showModalOrderCreated,
        loadingCreateOrder: false,
      };
    case 'SET_CREATED_ORDER':
      return {
        ...state,
        createdOrderId: action.createdOrderId,
        showModalOrderCreated: true,
        orderStatusAndPaymentInfo: {
          status: { value: 'ORDER_CREATED' },
          paymentCarrier: undefined,
          paymentInfo: undefined,
        },
      };
    case 'SET_ORDER_STATUS_AND_PAYMENT_INFO':
      return { ...state, orderStatusAndPaymentInfo: action.orderStatusAndPaymentInfo };

    case 'IS_MOBILE_DEVICE':
      return { ...state, isMobileDevice: action.isMobileDevice };
    case 'SHOW_CITIES_MODAL':
      return { ...state, isShowingCitiesModal: action.isShowingCitiesModal };
    default:
      return state;
  }
};

export default Reducer;
