import React from 'react';
import NumberFormat from 'react-number-format';

export function toCurrency(value, colorR) {
  const color = colorR || 'black';
  return (
    <NumberFormat
      value={parseInt(value, 10)}
      displayType="text"
      thousandSeparator
      style={{ color }}
      prefix=" $"
    />
  );
}

export function getCoverImage(productMedia, dimensions) {
  for (let i = 0; i < productMedia.length; i += 1) {
    const productM = productMedia[i];
    if (productM.isCoverImage) {
      return productM.mediaURL
        .replace('.jpg', `${dimensions}.jpg`)
        .replace('.png', `${dimensions}.png`);
    }
  }
  return (productMedia[0]?.mediaURL || '')
    .replace('.jpg', `${dimensions}.jpg`)
    .replace('.png', `${dimensions}.png`);
}

export function capitalize(name) {
  if (typeof name !== 'string') return '';
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
