import React from 'react';
import PropTypes from 'prop-types';

import './skeletonLoader.scss';

export default function SkeletonLoader({ height, width, borderRadius = '14px' }) {
  const skeletonBaseStyle = { height, width, borderRadius };
  return <div className="SkeletonLoader" style={skeletonBaseStyle}></div>;
}

SkeletonLoader.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
};
